<img src="../../assets/img/Picture 1.jpg" style="max-height: 400px; max-width: 100%;" alt="">

<div style="padding: 10px;">
  <p>
    The overall objective of the project <b><a
        href="https://sagov.italy-albania-montenegro.eu/sites/sagov.italy-albania-montenegro.eu/files/2020-09/Transport%2520Connectivity%2520in%2520South%2520Adriatic%2520Area_The%2520Adriatic%2520Beltway_2020.pdf">“South
        Adriatic Connectivity Governance - SAGOV”</a></b> is to promote a Governance Platform for Transport Connectivity
    Initiatives linking the South Adriatic. It contributes to the better understanding, tailor-made support,
    coordination and promotion of best cases in connectivity.
    <br><br>
    The purpose of this <b>Connectivity Platform</b> is as follows:
    <br><br>
    To increase the visibility and coordination of connectivity initiatives linking Italy / Puglia Region, Albania and
    Montenegro. Its main outputs are: a case-study / pilot on connectivity and tool-box; and a critical mass of
    knowledge and skills on connectivity ready-to-use and transferable. The project has concluded the mapping of
    connectivity initiatives and projects in the South Adriatic area, of the connectivity actors, and of the existing
    connectivity dynamics. It has identified many initiatives at policy level and at project level; hard infrastructure
    or soft measures; at different stages of advancement; being developed at many platforms; supported by different
    financing instruments; and carried forward by different actors; involving different form of partnership; etc.
  </p>
  <p>
    <b>Methodological approach of the Platform
    </b>
    <br><br>
    This Connectivity Platform shows the transport infrastructure projects that are being planned and implemented in the
    framework of <a href="https://ec.europa.eu/transport/themes/infrastructure/ten-t_en">TEN-T</a> , <a
      href="https://wbif.eu/sectors/connectivity-agenda">Connectivity
      Agenda</a> , and are part of national and Regional Transport Plans in Italy (Puglia) and
    of the National Single Project Pipelines in Albania and Montenegro.

    The infrastructure projects shown in the map can be in different phases of maturity and cover four sub-sectors of
    transport sector: road, rail, air and maritime.

    In the case of road and rail, the projects involve investments in works in the whole segment, in the portions of
    segments or nods that connect at least two countries (road corridors of rail lines).
    In the case of air and maritime, project concern investments in works or nods that connect at least two countries
    (airport or ports). 

  </p>

  <p>
    <b> Project Partnership
    </b>
    <br><br>
    <b> Lead Partner
    </b>
    <br>
    <a href="https://www.cdinstitute.eu">Instituti për Bashkëpunim dhe Zhvillim (AL) </a>
    <br><br>
    <b> Project Partners
    </b>
    <br>
    <a href="https://www.infrastruktura.gov.al/">Ministria e Transportit dhe Infrastrukturës (AL)</a>
    <br>
    <a href="https://www.regione.puglia.it/"> Regione Puglia – Sezione Relazioni Internazionali (IT)</a>
    <br>
    <a href="https://www.emim.org/">Evropski pokret u Crnoj Gori (ME)</a>
    <br>
    <a href="https://www.msp.gov.me/ministarstvo"> Ministarstvo saobraćaja i pomorstva (ME)</a>
    <br>
    <br>
    <b>Associated Partners</b>
    <br>
    <a href="https://www.financa.gov.al/"> Ministria e Financave dhe Ekonomisë (AL)</a>
    <br>
    <a href="https://www.mit.gov.it/"> Ministero delle Infrastrutture e dei Trasporti - Comando Generale del Corpo e
      delle Capitanerie di porto</a>
    <br><br>
    <b>Project duration is: April 2018 – December 2020</b>
    <br><br>
    <b><u><a href="/assets/disclaimer.html" style="color: black;" target="_blank">Disclaimer</a></u></b>

  </p>
</div>