import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import * as L from 'leaflet';
import { tileLayer, latLng, polygon, polyline } from 'leaflet';
import data from '../../assets/data.json';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable'
import { fontMono } from 'src/assets/noto-mono';
import { environment } from 'src/environments/environment';



@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss']
})
export class MapComponent implements OnInit {
  @ViewChild('simple_table') simple_table: ElementRef;
  basic = false;
  lastSelectionCategory = '';
  lastSelectionIndex = -1;
  filterId;
  mobile = false;
  pdfMake;
  _d;
  _toggled = false;
  form: any = {
    coordinates: [],
    projectTitle: '',
    sector: '',
    subSector: '',
    corridor: '',
    portionFrom: '',
    portionTo: '',
    country: '',
    other: '',
    risks: '',
    owner: '',
    principalProjectProponent: '',
    LGUMunicipalitiesImpacted: '',
    private: '',
    corruption: '',
    strategicRelevance: '',
    generalDescription: '',
    commercialSetUpBusinessDevelopment: '',
    lengthOfStreet: '',
    maxDesignSpeed: '',
    freightAxleLoad: '',
    maxTrainLines: '',
    cargoCapacity: '',
    bearingCapacity: '',
    noOfTrafficLanes: '',
    estimatedRoI: '',
    estimatedImpact: '',
    totalCostEstCapexOpex: '',
    intelligentTransportSystem: '',
    emergencyLanes: '',
    tollingSystem: '',
    seeto: '',
    enc: '',
    bri: '',
    otherProjectDef: '',
    preFeasability: {
      desc: '',
      stage: '',
      estimatedCost: '',
      financers: ''
    },
    conceptualDesign: {
      desc: '',
      stage: '',
      estimatedCost: '',
      financers: ''
    },
    feasibilityStudy: {
      desc: '',
      stage: '',
      estimatedCost: '',
      financers: ''
    },
    preliminaryDesign: {
      desc: '',
      stage: '',
      estimatedCost: '',
      financers: ''
    },
    esia: {
      desc: '',
      stage: '',
      estimatedCost: '',
      financers: ''
    },
    permitsAuthorization: {
      desc: '',
      stage: '',
      estimatedCost: '',
      financers: ''
    },
    permitsLea: {
      desc: '',
      stage: '',
      estimatedCost: '',
      financers: ''
    },
    mainDesignDetailedDesign: {
      desc: '',
      stage: '',
      estimatedCost: '',
      financers: ''
    },
    preparationTender: {
      desc: '',
      stage: '',
      costCapex: '',
      financers: ''
    },
    contractingWorks: {
      desc: '',
      stage: '',
      costCapex: '',
      financers: ''
    },
    contractingOfSupervision: {
      desc: '',
      stage: '',
      costCapex: '',
      financers: '',
      works: {
        desc: '',
        stage: '',
        estimatedCost: '',
        financers: ''
      },
      qualityAssurance: {
        desc: '',
        stage: '',
        estimatedCost: '',
        financers: ''
      }
    },
    commisioningHandover: {
      desc: '',
      estCost: '',
      financers: ''
    },
    operations: {
      desc: '',
      estCost: '',
      financers: ''
    },
    cashflowControlAccounting: {
      desc: '',
      estCost: '',
      financers: ''
    },
    hrTaxLegal: {
      desc: '',
      estCost: '',
      financers: ''
    },
    controlSuperVisionMonitoring: {
      desc: '',
      estCost: '',
      financers: ''
    }
  }

  planning = [
    {
      name: "Pre-feasibility",
      enable: false
    },
    {
      name: "Conceptual Design",
      enable: false
    },
    {
      name: "Feasibility Study",
      enable: false
    },
    {
      name: "Preliminary design",
      enable: false
    },
    {
      name: "Esia",
      enable: false
    },
    {
      name: "Permits & authorizations plan",
      enable: false
    },
    {
      name: "Permits LEA",
      enable: false
    },
    {
      name: "Main design / detailed design",
      enable: false
    }
  ];

  construction = [
    {
      name: "Preparation of Tender",
      enable: false
    },
    {
      name: "Contracting Works",
      enable: false
    },
    {
      name: "Contracting of Supervision",
      enable: false
    },
    {
      name: "Construction",
      enable: false,
      items: [
        {
          name: "Works",
          enable: false
        }, {
          name: "Quality Assurance",
          enable: false
        }
      ]
    }
  ]

  operations = [
    {
      name: "Commissioning / Handover",
      enable: false
    },
    {
      name: "Operations",
      enable: false
    },
    {
      name: "Cashflow / control / accounting",
      enable: false
    },
    {
      name: "HR / TAX / LEGAL",
      enable: false
    },
    {
      name: "Control / Supervision / Monitoring",
      enable: false
    }
  ]


  emptyOperations = {
    "commisioningHandover": {
      "estCost": "",
      "Financers": ""
    },
    "operations": {
      "estCost": "",
      "Financers": ""
    },
    "cashflowControlAccounting": {
      "estCost": "",
      "Financers": ""
    },
    "hrTaxLegal": {
      "estCost": "",
      "Financers": ""
    },
    "controlSuperVisionMonitoring": {
      "estCost": "",
      "Financers": ""
    }
  }
  emptyConstruction = {
    "preparationTender": {
      "stage": "",
      "costCapex": "",
      "Financers": ""
    },
    "contractingWorks": {
      "stage": "",
      "costCapex": "",
      "Financers": ""
    },
    "contractingOfSupervision": {
      "works": {
        "stage": "",
        "estimatedCost": "",
        "Financers": ""
      },
      "qualityAssurance": {
        "stage": "",
        "estimatedCost": "",
        "Financers": ""
      }
    }
  }
  emptyPlanning = {
    preFeasability: {
      desc: '',
      stage: '',
      estimatedCost: '',
      financers: ''
    },
    conceptualDesign: {
      desc: '',
      stage: '',
      estimatedCost: '',
      financers: ''
    },
    feasibilityStudy: {
      desc: '',
      stage: '',
      estimatedCost: '',
      financers: ''
    },
    preliminaryDesign: {
      desc: '',
      stage: '',
      estimatedCost: '',
      financers: ''
    },
    esia: {
      desc: '',
      stage: '',
      estimatedCost: '',
      financers: ''
    },
    permitsAuthorization: {
      desc: '',
      stage: '',
      estimatedCost: '',
      financers: ''
    },
    permitsLea: {
      desc: '',
      stage: '',
      estimatedCost: '',
      financers: ''
    },
    mainDesignDetailedDesign: {
      desc: '',
      stage: '',
      estimatedCost: '',
      financers: ''
    },
  }
  polylines: L.LayerGroup = L.layerGroup();

  subSectorSelection = {
    air: false,
    maritime: false,
    road: false,
    rail: false
  }
  projectProgressSelection = {
    planning: true,
    construction: true,
    operations: true,
  };

  map;
  options = {
    layers: [
      tileLayer('https://gisco-services.ec.europa.eu/maps/tiles/OSMPositronCompositeEN/EPSG3857/{z}/{x}/{y}.png', { maxZoom: 18, attribution: 'Disclaimer: Infrastructure projects in the map are styled accordingly, and may not reflect their exact trace or GIS location. Progress stage is updated regularly, although some delays due to timing of their official publication may occur.' })
      // tileLayer('https://europa.eu/webtools/maps/tiles/osm-ec//{z}/{x}/{y}.png', { maxZoom: 18, attribution: 'Disclaimer: Infrastructure projects in the map are styled accordingly, and may not reflect their exact trace or GIS location. Progress stage is updated regularly, although some delays due to timing of their official publication may occur.' })
    ],
    zoom: 8,
    center: latLng(41.047804, 19.005773)
  };

  constructor(private cd: ChangeDetectorRef) { }

  ngOnInit(): void {
    if (window.screen.width < 780) { // 768px portrait
      this.mobile = true;
      console.log(window.screen.width)
    }
  }

  onMapReady(map) {
    this.map = map;

    this.filter();
  }

  onFilter() {
    clearTimeout(this.filterId);
    this.filterId = setTimeout(() => {
      this.filter();
    }, 100);
  }

  deselectAllFilter() {
    for (let i = 0; i < this.planning.length; i++) {
      this.planning[i].enable = false;
    }
    for (let i = 0; i < this.operations.length; i++) {
      this.operations[i].enable = false;
    }
    for (let i = 0; i < this.construction.length; i++) {
      if (this.construction[i].items) {
        this.construction[i].items[0].enable = false;
        this.construction[i].items[1].enable = false;
      } else {
        this.construction[i].enable = false;
      }
    }
  }

  isEverythingDeselected() {
    let ret = true;
    for (let i = 0; i < this.planning.length; i++) {
      if (this.planning[i].enable) ret = false;
    }
    for (let i = 0; i < this.operations.length; i++) {
      if (this.operations[i].enable) ret = false;
    }
    for (let i = 0; i < this.construction.length; i++) {
      if (this.construction[i].items) {
        if (this.construction[i].items[0].enable) ret = false;
        if (this.construction[i].items[1].enable) ret = false;
      } else {
        if (this.construction[i].enable) ret = false;
      }
    }
    return ret;
  }

  filter() {

    if (this.polylines) {
      this.map.removeLayer(this.polylines);
    }
    let polylines: L.LayerGroup = L.layerGroup();

    let feature = [];

    let filteredData = data.filter(element => {
      return (this.subSectorSelection.air && (element.subSector === 'Air')) ||
        (this.subSectorSelection.maritime && (element.subSector === 'Maritime')) ||
        (this.subSectorSelection.road && (element.subSector === 'Road')) ||
        (this.subSectorSelection.rail && (element.subSector === 'Rail'));
    })
    if (!this.isEverythingDeselected()) {
      filteredData = filteredData.filter(element => {
        let ret = false;
        this.planning.forEach(item => {
          if (item.enable) {
            if (item.name === 'Pre-feasibility') {
              if (element.preFeasability.stage === 'true') ret = true;
              if (element.preFeasability.estimatedCost) ret = true;
              if (element.preFeasability.Financers) ret = true;
            }
            if (item.name === 'Conceptual Design') {
              if (element.conceptualDesign.stage) ret = true;
              if (element.conceptualDesign.estimatedCost) ret = true;
              if (element.conceptualDesign.Financers) ret = true;
            }
            if (item.name === 'Feasibility study') {
              if (element.feasibilityStudy.stage) ret = true;
              if (element.feasibilityStudy.estimatedCost) ret = true;
              if (element.feasibilityStudy.Financers) ret = true;
            }
            if (item.name === 'Preliminary design') {
              if (element.preliminaryDesign.stage) ret = true;
              if (element.preliminaryDesign.estimatedCost) ret = true;
              if (element.preliminaryDesign.Financers) ret = true;
            }
            if (item.name === 'Esia') {
              if (element.esia.stage) ret = true;
              if (element.esia.estimatedCost) ret = true;
              if (element.esia.Financers) ret = true;
            }
            if (item.name === 'Permits & authorizations plan') {
              if (element.permitsAuthorization.stage) ret = true;
              if (element.permitsAuthorization.estimatedCost) ret = true;
              if (element.permitsAuthorization.Financers) ret = true;
            }
            if (item.name === 'Permits LEA') {
              if (element.permitsLea.stage) ret = true;
              if (element.permitsLea.estimatedCost) ret = true;
              if (element.permitsLea.Financers) ret = true;
            }
            if (item.name === 'Main design / detailed design') {
              if (element.permitsLea.stage) ret = true;
              if (element.permitsLea.estimatedCost) ret = true;
              if (element.permitsLea.Financers) ret = true;
            }
          }
        });

        this.construction.forEach(item => {
          if (item.enable) {
            if (item.name === 'Preparation of Tender') {
              if (element.preparationTender.stage === 'true') ret = true;
              if (element.preparationTender.costCapex) ret = true;
              if (element.preparationTender.Financers) ret = true;
            }
            if (item.name === 'Contracting Works') {
              if (element.contractingWorks.stage === 'true') ret = true;
              if (element.contractingWorks.costCapex) ret = true;
              if (element.contractingWorks.Financers) ret = true;
            }
            if (item.name === 'Construction') {
              if (item.items) {
                if (item.items[0].enable) {
                  if (element.contractingOfSupervision.works.stage === 'true') ret = true;
                  if (element.contractingOfSupervision.works.estimatedCost) ret = true;
                  if (element.contractingOfSupervision.works.Financers) ret = true;
                }
                if (item.items[1].enable) {
                  if (element.contractingOfSupervision.qualityAssurance.stage === 'true') ret = true;
                  if (element.contractingOfSupervision.qualityAssurance.estimatedCost) ret = true;
                  if (element.contractingOfSupervision.qualityAssurance.Financers) ret = true;
                }
              }
            }
          }
        });

        this.operations.forEach(item => {
          if (item.enable) {
            if (item.name === 'Commissioning/Handover') {
              if (element.commisioningHandover.Financers) ret = true;
              if (element.commisioningHandover.estCost) ret = true;
            }
            if (item.name === 'Operations') {
              if (element.operations.Financers) ret = true;
              if (element.operations.estCost) ret = true;
            }
            if (item.name === 'Cashflow/control/accounting') {
              if (element.cashflowControlAccounting.Financers) ret = true;
              if (element.cashflowControlAccounting.estCost) ret = true;
            }
            if (item.name === 'HR / TAX / LEGAL') {
              if (element.hrTaxLegal.Financers) ret = true;
              if (element.hrTaxLegal.estCost) ret = true;
            }
            if (item.name === 'Control / Supervision / Monitoring') {
              if (element.controlSuperVisionMonitoring.Financers) ret = true;
              if (element.controlSuperVisionMonitoring.estCost) ret = true;
            }
          }
        });

        return ret;
      });

    }

    filteredData.forEach((data: any) => {
      if (data.coordinates) {
        polylines.addLayer(this.drawShape(data).bindPopup(`
        <table class='custom' style="min-width: 400px;">
        <h5 style="font-weight: bold">${data.projectTitle ? data.projectTitle : ' '}</h5>

        
        <tr style="background-color: lightgray">
          <td>Subsector</td>
          <td style="min-width: 50px; float: left; text-align-last: left; text-align: left;">${data.subSector ? data.subSector : ' '}</td>
        </tr>
        <tr>
          <td>Corridor / Route</td>
          <td style="min-width: 50px; float: left; text-align-last: left; text-align: left;">${data.corridor ? data.corridor : ' '}</td>
        </tr>
        <tr style="background-color: lightgray">
          <td>Owner</td>
          <td style="min-width: 50px; float: left; text-align-last: left; text-align: left;">${data.owner ? data.owner : ' '}</td>
        </tr>
        <tr>
          <td>Principal</td>
          <td style="min-width: 50px; float: left; text-align-last: left; text-align: left;">${data.principal ? data.principal : ' '}</td>
        </tr>
        <tr style="background-color: lightgray">
          <td>Key Initiative</td>
          <td></td>
        </tr>
        <tr>
          <td>Total Cost Estimation</td>
          <td style="min-width: 50px; float: left; text-align-last: left; text-align: left;">${data?.totalCostEstCapexOpex} €</td>
        </tr>
        <tr style="background-color: lightgray">
          <td>Stage</td>
          <td style="min-width: 50px; float: left; text-align-last: left; text-align: left;">${this.getStage(data)}</td>
        </tr>
        <tr>
        <td></td>
        <td>      <a  id="button-submit" style="cursor:pointer" right: 10px;'> more</a> </td>
        </tr>
      </table>
        `, {
          minWidth: 400
        }).on("preclick", () => {
          this.form = data;
        })
          .on("click", () => {
            let buttonSubmit = L.DomUtil.get('button-submit');
            L.DomEvent.addListener(buttonSubmit, 'click', async () => {
              this.printPDF(data);
            });
          }));
        feature.push(this.drawShape(data));
      }

    });

    // 3. Bari (Italy) - Vlora (Albania | Port
    let data6: any = {};
    data6.coordinates = [{ "lat": 41.1290213474951, "lng": 16.864013671875004 }, { "lat": 40.48038142908172, "lng": 19.478759765625004 }];
    polylines.addLayer(this.drawShape(data6, 'rgba(51, 118, 180, 0.5)', 3, "2 8"));

    // 6. Brindisi (Italy) - Vlora (Albania) | Port
    let data5: any = {};
    data5.coordinates = [{ "lat": 40.64730356252251, "lng": 17.940673828125004 }, { "lat": 40.47202439692057, "lng": 19.489746093750004 }];
    polylines.addLayer(this.drawShape(data5, 'rgba(51, 118, 180, 0.5)', 3, "2 8"));

    // 5. Brindisi (Italy) - Durres (Albania) | Port
    let data4: any = {};
    data4.coordinates = [{ "lat": 40.6306300839918, "lng": 17.940673828125004 }, { "lat": 41.3108238809182, "lng": 19.434814453125004 }];
    polylines.addLayer(this.drawShape(data4, 'rgba(51, 118, 180, 0.5)', 3, "2 8"));

    // 1.Bari (Italy) - Bar (Montenegro) | Port
    let data3: any = {};
    data3.coordinates = [{ "lat": 41.12074559016745, "lng": 16.858520507812504 }, { "lat": 42.094146370922736, "lng": 19.088745117187504 }];
    polylines.addLayer(this.drawShape(data3, 'rgba(51, 118, 180, 0.5)', 3, "2 8"));

    // 2. Bari (Italy) - Durres( Albania) | Port
    let data2: any = {};
    data2.coordinates = [{ "lat": 41.3108238809182, "lng": 19.434814453125004 }, { "lat": 41.12074559016745, "lng": 16.858520507812504 }];
    polylines.addLayer(this.drawShape(data2, 'rgba(51, 118, 180, 0.5)', 3, "2 8"));

    // 4. Bari (Italy) - Tirana (Albania) | Airport
    let data1: any = {};
    data1.coordinates = [{ "lat": 41.12074559016745, "lng": 16.875000000000004 }, { "lat": 41.343824581185714, "lng": 19.819335937500004 }];
    polylines.addLayer(this.drawShape(data1, 'rgba(143, 143, 143, 0.5)', 3, "2 8"));

    this.polylines = polylines;


    this.polylines.addTo(this.map)
    let featureGroup: L.FeatureGroup = L.featureGroup(feature);

    if (feature) {
      if (feature.length > 0) {
        // this.map.fitBounds(featureGroup.getBounds());
      }
    }
  }

  private drawShape(data, customColor?, weight?, dashArray?) {
    if (!data.coordinates) {
      return;
    }
    if (data.coordinates.length === 1) {
      let html = '';
      if (data.subSector === 'Air') {
        html = `<i class="fas fa-plane"></i>`
      }
      if (data.subSector === 'Maritime') {
        html = `<i class="fas fa-anchor"></i>`
      }
      return L.marker(L.latLng(data.coordinates[0].lat, data.coordinates[0].lng)
        ,
        {
          icon: L.divIcon({
            html: `<div style="height: 24px; width: 24px; font-size: 24px;">
                      ${html}
                  </div>`
            ,
            className: 'noclass',
            iconAnchor: [13, 9],
            iconSize: [24, 24]
          }),
        },
      );
    }
    let color;
    switch (data.subSector) {
      case 'Air':
        color = 'rgba(143, 143, 143)';
        break;
      case 'Road':
        color = 'rgba(163, 113, 80)';
        break;
      case 'Rail':
        color = 'rgba(168, 205, 111)'
        break;
      default:
        color = 'rgba(51, 118, 180)';
        break;
    }

    let coordinates = [];
    data.coordinates.forEach(element => {
      coordinates.push([
        element.lat, element.lng
      ])
    });
    return L.polyline([
      coordinates
    ],
      {
        weight: weight ? weight : 7,
        color: customColor ? customColor : color,
        opacity: 1,
        // smoothFactor: 0.1,
        noClip: true,
        dashArray: dashArray ? dashArray : null
      }
    );

  }


  private _contains(obj1, obj2) {
    const obj2keys = Object.keys(obj2);
    for (let i = 0; i < obj2keys.length; i++) {
      const key = obj2keys[i];
      if (obj1.hasOwnProperty(key)) {
        if (typeof (obj1[key]) === typeof (obj2[key])) {
          if (typeof (obj1[key]) === 'object') {
            if (Object.keys(obj1[key]).length !== Object.keys(obj2[key]).length)
              return false;
            const res = this._contains(obj1[key], obj2[key])
            if (!res)
              return false;
          } else {
            if (obj1[key] !== obj2[key])
              return false;
          }
        } else {
          return false;
        }
      } else
        return false;
    }
    return true;
  }

  private contains(object1, object2) {
    let res = false;
    res = this._contains(object1, object2);
    const keys = Object.keys(object1);
    for (let index = 0; index < keys.length; index++) {
      const key = keys[index];
      if (res)
        return res;
      else if (typeof (object1[key]) === 'object') {
        res = this.contains(object1[key], object2);
      }
    }
    return res;
  }

  getStage(element) {
    let ret = '';
    if (!this.contains(element, this.emptyPlanning)) {
      for (const property in this.emptyPlanning) {
        if (element[property]) {
          if (element[property].desc || element[property].stage || element[property].estimatedCost
            || element[property].financers || element[property].costCapex
          ) {
            let text = property.replace(/([A-Z])/g, " $1");
            let finalResult = text.charAt(0).toUpperCase() + text.slice(1);
            ret = `Planning / ${finalResult} `;
          }
        }
      }
    }

    if (!this.contains(element, this.emptyConstruction)) {
      for (const property in this.emptyConstruction) {
        if (element[property]) {
          if (element[property].desc || element[property].stage || element[property].estimatedCost
            || element[property].financers || element[property].costCapex
          ) {
            let text = property.replace(/([A-Z])/g, " $1");
            let finalResult = text.charAt(0).toUpperCase() + text.slice(1);
            ret = `Construction / ${finalResult} `;
          }
        }
      }
    }

    if (!this.contains(element, this.emptyOperations)) {
      for (const property in this.emptyOperations) {
        if (element[property]) {
          if (element[property].desc || element[property].stage || element[property].estimatedCost
            || element[property].financers || element[property].costCapex
          ) {
            let text = property.replace(/([A-Z])/g, " $1");
            let finalResult = text.charAt(0).toUpperCase() + text.slice(1);
            ret = `Operations / ${finalResult} `;
          }
        }
      }
    }

    return ret;
    // return 'Operations / Monitoring';

  }

  selectBeforeConstruction(index, action: boolean, item?) {
    this.deselectAllFilter();
    if ((this.lastSelectionCategory === 'construction') && (this.lastSelectionIndex === index)) {
      this.lastSelectionCategory = '';
      this.lastSelectionIndex = -1;
    }
    else {
      this.lastSelectionCategory = 'construction';
      this.lastSelectionIndex = index;
      for (let j = 0; j < this.planning.length; j++) {
        if (this.construction[j]) {
          if (this.construction[j].items) {
            if (index < 3) {
              this.construction[j].items[0].enable = !action;
              this.construction[j].items[1].enable = !action;
            } else {
              if (item) {
                this.construction[j].items[0].enable = action;
                this.construction[j].items[1].enable = action;
              } else {
                this.construction[j].items[0].enable = action;
              }
            }
          } else {
            index >= j ?
              this.construction[j].enable = action :
              this.construction[j].enable = !action
          }
        }
      }
    }
  }
  selectBeforePlanning(index, action: boolean) {
    this.deselectAllFilter();
    if ((this.lastSelectionCategory === 'planning') && (this.lastSelectionIndex === index)) {
      this.lastSelectionCategory = '';
      this.lastSelectionIndex = -1;
    }
    else {
      this.lastSelectionCategory = 'planning';
      this.lastSelectionIndex = index;
      for (let j = 0; j < this.planning.length; j++) {
        index >= j ?
          this.planning[j].enable = action :
          this.planning[j].enable = !action
      }
    }
  }

  selectBeforeOperations(index, action: boolean) {
    this.deselectAllFilter();
    if ((this.lastSelectionCategory === 'operations') && (this.lastSelectionIndex === index)) {
      this.lastSelectionCategory = '';
      this.lastSelectionIndex = -1;
    }
    else {
      this.lastSelectionCategory = 'operations';
      this.lastSelectionIndex = index;
      for (let j = 0; j < this.operations.length; j++) {
        index >= j ?
          this.operations[j].enable = action :
          this.operations[j].enable = !action;
      }
    }

  }

  async printPDF(data) {
    this.form = data;
    let table = document.getElementById('simple_table')
    table.style.display = 'block'
    // let subSector: HTMLElement;
    // switch (this.form.subSector) {
    //   case "Air": 

    //     subSector = document.getElementById('subSector_air');
    //     subSector.style.display = 'block'
    //     break;

    //   case "Maritime":
    //     subSector = document.getElementById('subSector_maritime');
    //     subSector.style.display = 'block'
    //     break;

    //   case "Rail":
    //     subSector = document.getElementById('subSector_rail');
    //     subSector.style.display = 'block'
    //     break;

    //   case "Road":
    //     subSector = document.getElementById('subSector_road');
    //     subSector.style.display = 'block'
    //     break;

    //   default:
    //     break;
    // }
    this.cd.detectChanges()
    this.cd.markForCheck();
    this._d = new Date()
    let doc = new jsPDF();
    doc.addFileToVFS('Amiri-Regular.ttf', fontMono);
    doc.addFont('Amiri-Regular.ttf', 'Amiri', 'normal');
    doc.setFont('Amiri'); // set font
    doc.setFontSize(20);
    autoTable(doc, {
      html: '#simple_table',
      styles: {
        font: 'Amiri',
        fontStyle: 'normal',
      },
      didDrawPage: function (data) {
        // Header

        doc.setFontSize(20);
        doc.setTextColor(40);
        const sagovJPG = `/assets/img/sagov.jpg`;
        if (sagovJPG) {
          doc.addImage(sagovJPG, 'JPEG', data.settings.margin.left, 2, 80, 26);
        }

        // Footer
        // @ts-ignore
        let str = "Page " + doc.internal.getNumberOfPages();

        let today = new Date();
        let dd = String(today.getDate()).padStart(2, '0');
        let mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        let yyyy = today.getFullYear();
        let generated = 'Generated at ' + mm + '/' + dd + '/' + yyyy

        let disclaimer = 'Disclaimer: Infrastructure projects in the map are styled and may not reflect their exact trace or GIS location.'
        let disclaimer1 = 'Progress stage is updated regularly although, some delays due to timing of their official publication, may occur.'
        // Total page number plugin only available in jspdf v1.0+
        if (typeof doc.putTotalPages === 'function') {
          str = str;
        }
        doc.setFontSize(7);

        let pageSize = doc.internal.pageSize;
        let pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
        doc.text(str, data.settings.margin.left, pageHeight - 13);
        doc.text(generated, 160, pageHeight - 13);
        doc.text(disclaimer, data.settings.margin.left, pageHeight - 8);
        doc.text(disclaimer1, data.settings.margin.left, pageHeight - 3);
        // doc.text(str, data.settings.margin.left, pageHeight - 10);
      },
      margin: { bottom: 28, top: 30 }
    })

    doc.save(`Sagov-${this.form.projectTitle}.pdf`);
    setTimeout(() => {
      table.style.display = 'none'

    }, 1000);
  }

  toggle() {
    this._toggled = !this._toggled;
    setTimeout(() => {
      this.map.invalidateSize();
    }, 1000);
  }

}
