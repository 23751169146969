<button *ngIf="mobile" class=" btn-primary btn-circle button-top-right" (click)="basic = true"><i
        class="fas fa-filter"></i></button>
<div class="clr-row" style="height: calc(100% - 80px); padding: 10px; position: relative">

    <ng-container *ngIf="!mobile">
        <div class="clr-col-3" style="max-width: 300px; padding-right: 0px !important">
            <div class="clr-col-4 clr-col-sm-4 clr-col-md-4 clr-col-lg-2 clr-col-xl-2 data"
                style="width: 100%; max-width: 300px;padding-right: 0px !important ;" *ngIf="!mobile">

                <clr-tabs>
                    <clr-tab>
                        <button clrTabLink id="link1">Global Value Chains </button>
                        <clr-tab-content id="content1" *clrIfActive="true">
                            <button class="btn btn-outline btn-block "
                                [class]="subSectorSelection.textile ? 'selectedTextile' : ''"
                                (click)="subSectorSelection.textile = !subSectorSelection.textile; onFilter()">
                                <span style="margin-right: 10px">Textile and Leather </span>
                            </button>
                            <button class="btn btn-outline btn-block "
                                [class]="subSectorSelection.food ? 'selectedFood' : ''"
                                (click)="subSectorSelection.food = !subSectorSelection.food; onFilter()">
                                <span style="margin-right: 10px"> Food and Agriculture </span>
                            </button>
                            <button class="btn btn-outline btn-block "
                                [class]="subSectorSelection.other ? 'selectedOther' : ''"
                                (click)="subSectorSelection.other = !subSectorSelection.other; onFilter()">
                                <span style="margin-right: 10px"> Other </span>
                            </button>
                            <p>
                                <b>Note :</b>
                                Initially, we have identified the categories of "Textile and Leather" and "Food and
                                Agriculture", while other categories will be added in the future.
                                <br>
                                Each industry of the “Global Value Chains” Tab can be easily identified with a
                                particular colour:


                            </p>
                            <br>
                            <ul style="font-weight: 800;">
                                <li style="color: rgba(163, 113, 80);"> Brown - TEXTILE AND LEATHER
                                </li>
                                <li style='color: rgba(168, 205, 111);'> Green - FOOD AND AGRICULTURE
                                </li>
                                <li style="color: rgba(51, 118, 180);">
                                    Blue - OTHER

                                </li>
                            </ul>
                            <br>
                            <p>

                                In the <b>“Global Value Chains” </b>
                                Tab, you can select any of the industries such as “Textile
                                and Leather”, “Food and Agriculture” and “Other”, and the respective company will be
                                visualized with a Pin in the map. If you click on the Pin, a pop-up box will show more
                                information about the selected company. Learn more on the categories of our database
                                <a href="../../assets/Pershkrimi i Metodologjise per SMEs (1) (1).pdf" target="_blank"
                                    style="cursor: pointer;"><u> here</u></a>.
                            </p>
                            <p>
                                The Map also contains information about the  <i class="fas fa-flag" style="color: gray"></i>   Free Economic Zones established in the Area.
                                Read  
                                <a style="cursor: pointer;" href="../../assets/Pershkrimi - Free Economic  Zones.pdf"
                                    target="_blank"><u>here</u></a>
                                for more information.
                            </p>
                            <!-- {{subSectorSelection | json}} -->
                        </clr-tab-content>
                    </clr-tab>

                </clr-tabs>

            </div>
        </div>
    </ng-container>
    <div class="clr-col">

        <div style="min-height: 300px; height: calc(100% - 50px); width: 100%;" leaflet
            (leafletMapReady)="onMapReady($event)" [leafletOptions]="options">
        </div>

    </div>
</div>




<table id="simple_table" style="display: none; font-family:'Courier New', Courier, monospace;" #simple_table>
    <tr>
        <td>General Information</td>
        <td></td>
        <td></td>
    </tr>
    <tr>
        <td>Enterprise Name</td>
        <td></td>
        <td>{{form.name}}</td>
    </tr>
    <tr>
        <td>Year Established</td>
        <td></td>
        <td>{{form.year}}</td>
    </tr>
    <tr>
        <td>Industry</td>
        <td></td>
        <td>{{form.industry}}</td>
    </tr>
    <tr>
        <td>Products Category</td>
        <td></td>
        <td>{{form.products}}</td>
    </tr>
    <tr>
        <td>Number of Employees</td>
        <td></td>
        <td>{{form.employees}}</td>
    </tr>
    <tr>
        <td>Address, Contact Email and tel</td>
        <td></td>
        <td>{{form.location}} , {{form.email}} , {{form.tel}} </td>
    </tr>
    <tr>
        <td>Official website</td>
        <td></td>
        <td>{{form.website}}</td>
    </tr>
</table>


<!-- "name"
"industry"
"products"
"location"
"employees"
"email"
"year"
"tel"
"website" -->