import { Component, OnInit } from '@angular/core';
import { async } from '@angular/core/testing';
import { DomSanitizer } from '@angular/platform-browser';
import L from 'leaflet';
import { DrawEvents, featureGroup, FeatureGroup, latLng, tileLayer } from 'leaflet';
import es6printJS from "print-js";

@Component({
  selector: 'app-project-form',
  templateUrl: './project-form.component.html',
  styleUrls: ['./project-form.component.scss']
})
export class ProjectFormComponent implements OnInit {
  drawItems: any = featureGroup();
  downloadJsonHref;
  latLngs = [];
  canvas;
  options = {
    layers: [
      tileLayer('https://gisco-services.ec.europa.eu/maps/tiles/OSMPositronCompositeEN/EPSG3857/{z}/{x}/{y}.png', { maxZoom: 18, attribution: 'Disclaimer: Infrastructure projects in the map are styled accordingly, and may not reflect their exact trace or GIS location. Progress stage is updated regularly, although some delays due to timing of their official publication may occur.' })
      // tileLayer('https://europa.eu/webtools/maps/tiles/osm-ec//{z}/{x}/{y}.png', { maxZoom: 18, attribution: 'OpenStreetMap' })
    ],
    zoom: 5,
    center: latLng(41.3275, 19.8187)
  };

  printTable = false;
  form = {
    coordinates: [],
    projectTitle: '',
    sector: '',
    subSector: '',
    corridor: '',
    portionFrom: '',
    portionTo: '',
    country: '',
    other: '',
    risks: '',
    owner: '',
    principalProjectProponent: '',
    LGUMunicipalitiesImpacted: '',
    private: '',
    corruption: '',
    strategicRelevance: '',
    generalDescription: '',
    commercialSetUpBusinessDevelopment: '',
    lengthOfStreet: '',
    maxDesignSpeed: '',
    freightAxleLoad: '',
    maxTrainLines: '',
    cargoCapacity: '',
    bearingCapacity: '',
    noOfTrafficLanes: '',
    estimatedRoI: '',
    estimatedImpact: '',
    totalCostEstCapexOpex: '',
    intelligentTransportSystem: '',
    emergencyLanes: '',
    tollingSystem: '',
    seeto: '',
    enc: '',
    bri: '',
    otherProjectDef: '',
    preFeasability: {
      desc: '',
      stage: '',
      estimatedCost: '',
      financers: ''
    },
    conceptualDesign: {
      desc: '',
      stage: '',
      estimatedCost: '',
      financers: ''
    },
    feasibilityStudy: {
      desc: '',
      stage: '',
      estimatedCost: '',
      financers: ''
    },
    preliminaryDesign: {
      desc: '',
      stage: '',
      estimatedCost: '',
      financers: ''
    },
    esia: {
      desc: '',
      stage: '',
      estimatedCost: '',
      financers: ''
    },
    permitsAuthorization: {
      desc: '',
      stage: '',
      estimatedCost: '',
      financers: ''
    },
    permitsLea: {
      desc: '',
      stage: '',
      estimatedCost: '',
      financers: ''
    },
    mainDesignDetailedDesign: {
      desc: '',
      stage: '',
      estimatedCost: '',
      financers: ''
    },
    preparationTender: {
      desc: '',
      stage: '',
      costCapex: '',
      financers: ''
    },
    contractingWorks: {
      desc: '',
      stage: '',
      costCapex: '',
      financers: ''
    },
    contractingOfSupervision: {
      desc: '',
      stage: '',
      costCapex: '',
      financers: '',
      works: {
        desc: '',
        stage: '',
        estimatedCost: '',
        financers: ''
      },
      qualityAssurance: {
        desc: '',
        stage: '',
        estimatedCost: '',
        financers: ''
      }
    },
    commisioningHandover: {
      desc: '',
      estCost: '',
      financers: ''
    },
    operations: {
      desc: '',
      estCost: '',
      financers: ''
    },
    cashflowControlAccounting: {
      desc: '',
      estCost: '',
      financers: ''
    },
    hrTaxLegal: {
      desc: '',
      estCost: '',
      financers: ''
    },
    controlSuperVisionMonitoring: {
      desc: '',
      estCost: '',
      financers: ''
    }
  }

  drawOptions = {
    // position: 'topright',
    draw: {
      circlemarker: false,
      rectangle: false,
      polygon: false,
      circle: false,
      marker: {
        icon: L.icon({
          iconSize: [25, 41],
          iconAnchor: [13, 41],
          iconUrl: 'assets/marker-icon.png',
          shadowUrl: 'assets/marker-shadow.png'
        })
      },
    },
    edit: {
      featureGroup: this.drawItems
    }
  };

  public onDrawCreated(e: any) {
    this.drawItems.addLayer((e as DrawEvents.Created).layer);
    console.log(e);
    if (e.layerType === 'marker') {
      this.latLngs = this.latLngs.concat(e.layer._latlng);
    } else {
      this.latLngs = this.latLngs.concat(e.layer._latlngs);
    }

  }
  open = false;
  selectedFile: File
  constructor(private sanitizer: DomSanitizer) { }

  ngOnInit(): void {
  }


  onFileChanged(event) {
    this.latLngs = [];
    this.selectedFile = event.target.files[0];
    const fileReader = new FileReader();
    fileReader.readAsText(this.selectedFile, "UTF-8");
    fileReader.onload = async () => {
      let tempConst = (JSON.parse(JSON.stringify(fileReader.result)));;
      this.form = (JSON.parse(tempConst));

      const coordinates = JSON.parse(tempConst).coordinates;
      if (coordinates) {
        let latLngs = [];
        coordinates.forEach(element => {
          latLngs.push([element.lat, element.lng])
          this.latLngs.push({
            lat: element.lat,
            lng: element.lng
          })
        });
        this.drawItems.addLayer(L.polyline(latLngs));
      }
    }
    fileReader.onerror = (error) => {
      alert(error);
    }
  }



  onUpload() {
    // upload code goes here
  }

  ab2str(buf) {
    return String.fromCharCode.apply(null, new Uint16Array(buf));
  }
  str2ab(str) {
    var buf = new ArrayBuffer(str.length * 2); // 2 bytes for each char
    var bufView = new Uint16Array(buf);
    for (var i = 0, strLen = str.length; i < strLen; i++) {
      bufView[i] = str.charCodeAt(i);
    }
    return buf;
  }



  downloadJson() {
    console.log(this.latLngs);
    this.form.coordinates = this.latLngs;
    var sJson = JSON.stringify(this.form);
    var element = document.createElement('a');
    element.setAttribute('href', "data:text/json;charset=UTF-8," + encodeURIComponent(sJson));
    element.setAttribute('download', "export.json");
    element.style.display = 'none';
    document.body.appendChild(element);
    element.click(); // simulate click
    document.body.removeChild(element);
  }

  // async printPDF() {
  //   let table = document.getElementById('simple_table')
  //   table.style.display = 'block'
  //   let subSector: HTMLElement;
  //   switch (this.form.subSector) {
  //     case "Air":
  //       subSector = document.getElementById('subSector_air');
  //       subSector.style.display = 'block'
  //       break;

  //     case "Maritime":
  //       subSector = document.getElementById('subSector_maritime');
  //       subSector.style.display = 'block'
  //       break;

  //     case "Rail":
  //       subSector = document.getElementById('subSector_rail');
  //       subSector.style.display = 'block'
  //       break;

  //     case "Road":
  //       subSector = document.getElementById('subSector_road');
  //       subSector.style.display = 'block'
  //       break;

  //     default:
  //       break;
  //   }
  //   es6printJS("simple_table", "html")
  //   setTimeout(() => {
  //     table.style.display = 'none'
  //   }, 1000);
  // }
}
