<div class="home">
  <img src="../../assets/img/homepage.png" style="width: 100%;">
</div>

<div style="padding: 10px; background-color: white;">


  <p style="font-size:large; text-align: center;">
    <span style="font-size: 30px; font-weight: 1000; ">
      WELCOME TO SAGOV – CONNECTIVITY PLATFORM
    </span>
    <br>

    The Portal offers a wealth of
    connectivity- and
    sustainability-related data to help policymakers,<br> researchers, businesses, and other stakeholders gather
    insights into the state of connectivity between: <span style="color: rgb(0, 74, 156); font-weight: bold;">Albania,
      Italy</span>
    and <span style="color: rgb(0, 74, 156); font-weight: bold;">Montenegro.</span>
  </p>

  <img class="indicators" src="../../assets/img/5.png" style=" width: 100%;">
</div>