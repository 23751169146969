<button *ngIf="mobile" class=" btn-primary btn-circle button-top-right" (click)="basic = true"><i
    class="fas fa-filter"></i></button>
<div class="clr-row" style="height: calc(100% - 80px); padding: 10px; position: relative">

  <ng-container *ngIf="!mobile">
    <div class="clr-col-3" style="max-width: 300px; padding-right: 0px !important">
      <div class="clr-col-4 clr-col-sm-4 clr-col-md-4 clr-col-lg-2 clr-col-xl-2 data"
        style="width: 100%; max-width: 300px;padding-right: 0px !important ;" *ngIf="!mobile">
        <!-- <div style="float: right; margin-bottom: -21px;">
          <a href="/assets/info.pdf" target="_blank">
            <i style="font-size: 15px;" class="far fa-question-circle"></i>
          </a>
        </div> -->

        <clr-tabs>
          <clr-tab>
            <button clrTabLink id="link1">Sub-sector</button>
            <clr-tab-content id="content1" *clrIfActive="true">

              <button class="btn btn-outline btn-block" [class]="subSectorSelection.air ? 'selectedAir' : ''"
                (click)="subSectorSelection.air = !subSectorSelection.air; onFilter()">
                <span style="margin-right: 10px">Air </span>
              </button>

              <button class="btn btn-outline btn-block" [class]="subSectorSelection.maritime ? 'selectedMaritime' : ''"
                (click)="subSectorSelection.maritime = !subSectorSelection.maritime; onFilter()">
                <span style="margin-right: 10px"> Maritime </span>
              </button>
              <button class="btn btn-outline btn-block" [class]="subSectorSelection.road ? 'selectedRoad' : ''"
                (click)="subSectorSelection.road = !subSectorSelection.road; onFilter()">
                <span style="margin-right: 10px"> Road </span>
              </button>
              <button class="btn btn-outline btn-block" [class]="subSectorSelection.rail ? 'selectedRail' : ''"
                (click)="subSectorSelection.rail = !subSectorSelection.rail; onFilter()">
                <span style="margin-right: 10px"> Rail </span>
              </button>

              <p>
                <b>Note</b>
                : Each of subsector can be easily identified through a specific colour:
              </p>
              <br>
              <ul style="font-weight: 800;">
                <li style="color: rgba(163, 113, 80);"> Brown - ROAD
                </li>
                <li style='color: rgba(168, 205, 111);'> Green - RAIL
                </li>
                <li style="color:rgba(143, 143, 143);"> Grey - AIR
                </li>
                <li style="color: rgba(51, 118, 180);">
                  Blue - MARITIME

                </li>
              </ul>
              <br>
              <p>
                In the <b>“Sub-Sector” </b>Tab, there it is possibility to select one (or more) sub- sectors (road,
                rail, air
                and
                maritime), and the respective segments/projects will be visualized in the map.
              </p>
              <p>
                You may also click on the segment/project to learn more about that specific project. A Project Passport
                summarizes more technical information about the project.
              </p>


              <!-- {{subSectorSelection | json}} -->
            </clr-tab-content>
          </clr-tab>
          <clr-tab>
            <button clrTabLink (click)="onFilter()">Project Progress</button>
            <clr-tab-content *clrIfActive="false">
              <clr-accordion>
                <clr-accordion-panel (clrAccordionPanelOpenChange)="onFilter()">
                  <clr-accordion-title style="font-size: large; font-weight: bolder;"> Planning</clr-accordion-title>
                  <clr-accordion-content *clrIfExpanded>
                    <clr-tree>
                      <ng-container *ngFor="let item of planning; let index = index">
                        <clr-tree-node [class]="item.enable ? 'highlited' : ''" style="font-size: medium"
                          (click)="item.enable = !!$event; onFilter(); selectBeforePlanning(index, !!$event);">
                          {{item.name}}
                        </clr-tree-node>
                      </ng-container>
                    </clr-tree>
                  </clr-accordion-content>
                </clr-accordion-panel>

                <clr-accordion-panel>
                  <clr-accordion-title style="font-size: large; font-weight: bolder;">Construction</clr-accordion-title>
                  <clr-accordion-content *clrIfExpanded>
                    <clr-tree>
                      <ng-container *ngFor="let item of construction.slice(0,3); let index = index">
                        <clr-tree-node [class]="item.enable ? 'highlited' : ''" style="font-size: medium"
                          (click)="item.enable = !!$event; onFilter(); selectBeforeConstruction(index, !!$event); ">
                          {{item.name}}
                        </clr-tree-node>

                      </ng-container>
                      <span style="margin-left: 11px; font-size: medium; margin-top: 14px;">
                        {{construction[3].name}} :
                      </span>
                      <clr-tree-node style="margin-left: 20px; font-size: medium;"
                        [class]="construction[3].items[0].enable ? 'highlited' : ''"
                        (click)="construction[3].items[0].enable = !!$event; onFilter(); selectBeforeConstruction(3, !!$event, 0); ">
                        {{construction[3].items[0].name}}
                      </clr-tree-node>
                      <clr-tree-node style="margin-left: 20px; font-size: medium;"
                        [class]="construction[3].items[1].enable ? 'highlited' : ''"
                        (click)="construction[3].items[1].enable = !!$event; onFilter(); selectBeforeConstruction(3, !!$event , 1); ">
                        {{construction[3].items[1].name}}
                      </clr-tree-node>
                    </clr-tree>
                  </clr-accordion-content>
                </clr-accordion-panel>
                <clr-accordion-panel>
                  <clr-accordion-title style="font-size: large; font-weight: bolder;"> Operations</clr-accordion-title>
                  <clr-accordion-content *clrIfExpanded>
                    <clr-tree>
                      <ng-container *ngFor="let item of operations; let index = index">

                        <clr-tree-node [class]="item.enable ? 'highlited' : ''" style="font-size: medium"
                          (click)="item.enable = !!$event; onFilter(); selectBeforeOperations(index, !!$event);">
                          {{item.name}}
                        </clr-tree-node>
                      </ng-container>
                    </clr-tree>
                  </clr-accordion-content>
                </clr-accordion-panel>
              </clr-accordion>

              <!-- {{projectProgressSelection | json}} -->
              <p>
                <b>Note</b>
                : Please select one (or more) sub- sectors: road, rail, air and maritime. in order to continue with
                the information under “Project Progress” Tab.
              </p>
              <p>
                The user can click on the routes (road and rail) and symbols (maritime and air) in the map and a pop-up
                box appears with the respective key data. The user can also download a detailed version of information
                on
                that project by clicking “more”.
              </p>
            </clr-tab-content>
          </clr-tab>
        </clr-tabs>

      </div>
    </div>
  </ng-container>
  <div class="clr-col">

    <div style="min-height: 300px; height: calc(100% - 50px); width: 100%;" leaflet
      (leafletMapReady)="onMapReady($event)" [leafletOptions]="options">
    </div>

  </div>
</div>



<table id="simple_table" style="display: none; font-family:'Courier New', Courier, monospace;" #simple_table>
  <tr>
    <td>General Information</td>
    <td></td>
    <td></td>
  </tr>
  <tr>
    <td></td>
    <td>Project Title</td>
    <td>{{form.projectTitle}}</td>
  </tr>
  <tr>
    <td></td>
    <td>Sector</td>
    <td>{{form.sector}}</td>
  </tr>
  <tr>
    <td></td>
    <td>Sub - Sector</td>
    <td>{{form.subSector}}</td>
  </tr>
  <tr>
    <td></td>
    <td>Corridor Route</td>
    <td>{{form.corridor}}</td>
  </tr>
  <tr>
    <td></td>
    <td>From</td>
    <td>{{form.portionFrom}}</td>
  </tr>
  <tr>
    <td></td>
    <td>To</td>
    <td>{{form.portionTo}}</td>
  </tr>
  <tr>
    <td></td>
    <td>Country(ies)</td>
    <td>{{form.country}}</td>
  </tr>
  <tr>
    <td></td>
    <td>Other</td>
    <td>{{form.other}}</td>
  </tr>
  <tr>
    <td>Owner Stakeholders</td>
    <td></td>
    <td></td>
  </tr>
  <tr>
    <td></td>
    <td>Owner</td>
    <td>{{form.owner}}</td>
  </tr>
  <tr>
    <td></td>
    <td>Principal / Project Proponent</td>
    <td>{{form.principalProjectProponent}}</td>
  </tr>
  <tr>
    <td></td>
    <td>LGU / Municipalities impacted</td>
    <td>{{form.LGUMunicipalitiesImpacted}}</td>
  </tr>
  <tr>
    <td></td>
    <td>Private (i.e. PPP) </td>
    <td>{{form.private}}</td>
  </tr>

  <tr>
    <td>Project Definition / Regional Dimension</td>
    <td></td>
    <td></td>
  </tr>
  <tr>
    <td></td>
    <td>Strategic Relevance</td>
    <td>{{form.strategicRelevance}}</td>
  </tr>
  <tr>
    <td></td>
    <td>General description </td>
    <td>{{form.generalDescription}}</td>
  </tr>
  <tr>
    <td></td>
    <td>Commercial set up & business development </td>
    <td>{{form.commercialSetUpBusinessDevelopment}}</td>
  </tr>
  <tr>
    <td></td>

    Technical data (length, etc)

  </tr>

  <tr>
    <td></td>
    <td>Length (km)</td>
    <td>{{form.lengthOfStreet}}</td>
  </tr>
  <tr>
    <td></td>
    <td>Max design speed (km/Hrs)</td>
    <td>{{form.maxDesignSpeed}}</td>
  </tr>
  <tr>
    <td></td>
    <td>Freight axle load (t)</td>
    <td>{{form.freightAxleLoad}}</td>
  </tr>
  <tr>
    <td></td>
    <td>Max train lines</td>
    <td>{{form.maxTrainLines}}</td>
  </tr>

  <tr>
    <td></td>
    <td>Length (km)</td>
    <td>{{form.lengthOfStreet}}</td>
  </tr>
  <tr>
    <td></td>
    <td>Max design speed </td>
    <td>{{form.maxDesignSpeed}}</td>
  </tr>
  <tr>
    <td></td>
    <td> No of traffic lanes per direction </td>
    <td>{{form.noOfTrafficLanes}}</td>
  </tr>
  <tr>
    <td></td>
    <td>Existence of emergency lanes</td>
    <td>{{form.emergencyLanes}}</td>
  </tr>
  <tr>
    <td></td>
    <td>Tolling system</td>
    <td>{{form.tollingSystem}}</td>
  </tr>
  <tr>
    <td></td>
    <td>Intelligent transport systems </td>
    <td>{{form.intelligentTransportSystem}}</td>
  </tr>

  <tr>
    <td></td>
    <td>Cargo capacity</td>
    <td>{{form.cargoCapacity}}</td>
  </tr>
  <tr>
    <td></td>
    <td>Bearing capacity</td>
    <td>{{form.bearingCapacity}}</td>
  </tr>
  <tr>
    <td></td>
    <td> No of traffic lanes per direction </td>
    <td>{{form.noOfTrafficLanes}}</td>
  </tr>

  <tr>
    <td></td>
    <td>Cargo capacity</td>
    <td>{{form.cargoCapacity}}</td>
  </tr>
  <tr>
    <td></td>
    <td>Bearing capacity</td>
    <td>{{form.bearingCapacity}}</td>
  </tr>
  <tr>
    <td></td>
    <td> No of traffic lanes per direction </td>
    <td>{{form.noOfTrafficLanes}}</td>
  </tr>
  <tr>
    <td></td>
    <td>SEETO</td>
    <td>{{form.seeto}}</td>
  </tr>
  <tr>
    <td></td>
    <td>ENC</td>
    <td>{{form.enc}}</td>
  </tr>
  <tr>
    <td></td>
    <td>BRI</td>
    <td>{{form.bri}}</td>
  </tr>
  <tr>
    <td></td>
    <td>Other</td>
    <td>{{form.otherProjectDef}}</td>
  </tr>
  <tr>
    <td></td>
    <td>Estimated RoI</td>
    <td>{{form.estimatedRoI}}</td>
  </tr>
  <tr>
    <td></td>
    <td>Estimated Impact</td>
    <td>{{form.estimatedImpact}}</td>
  </tr>
  <tr>
    <td></td>
    <td>Total cost est. (Capex & Opex)</td>
    <td>{{form.totalCostEstCapexOpex}}</td>
  </tr>

  <tr>
    <td>Planning</td>
    <td></td>
    <td></td>
  </tr>

  <tr>
    <td></td>
    <td>

      Pre-feasibility

    </td>
    <td>{{form.preFeasability.desc}}</td>
  </tr>
  <tr>
    <td></td>
    <td>Stage</td>
    <td>{{form.preFeasability.stage}}</td>
  </tr>
  <tr>
    <td></td>
    <td>Est Cost</td>
    <td>{{form.preFeasability.estimatedCost}}</td>
  </tr>
  <tr>
    <td></td>
    <td>Financers</td>
    <td>{{form.preFeasability.financers}}</td>
  </tr>
  <tr>
    <td></td>
    <td>

      Conceptual Design

    </td>
    <td>{{form.conceptualDesign.desc}}</td>
  </tr>
  <tr>
    <td></td>
    <td>Stage</td>
    <td>{{form.conceptualDesign.stage}}</td>
  </tr>
  <tr>
    <td></td>
    <td>Est Cost</td>
    <td>{{form.conceptualDesign.estimatedCost}}</td>
  </tr>
  <tr>
    <td></td>
    <td>Financers</td>
    <td>{{form.conceptualDesign.financers}}</td>
  </tr>
  <tr>
    <td></td>
    <td>

      Feasibility study

    </td>
    <td>{{form.feasibilityStudy.desc}}</td>
  </tr>
  <tr>
    <td></td>
    <td>Stage</td>
    <td>{{form.feasibilityStudy.stage}}</td>
  </tr>
  <tr>
    <td></td>
    <td>Est Cost</td>
    <td>{{form.feasibilityStudy.estimatedCost}}</td>
  </tr>
  <tr>
    <td></td>
    <td>Financers</td>
    <td>{{form.feasibilityStudy.financers}}</td>
  </tr>
  <tr>
    <td></td>
    <td>

      Preliminary design

    </td>
    <td>{{form.preliminaryDesign.desc}}</td>
  </tr>
  <tr>
    <td></td>
    <td>Stage</td>
    <td>{{form.preliminaryDesign.stage}}</td>
  </tr>
  <tr>
    <td></td>
    <td>Est Cost</td>
    <td>{{form.preliminaryDesign.estimatedCost}}</td>
  </tr>
  <tr>
    <td></td>
    <td>Financers</td>
    <td>{{form.preliminaryDesign.financers}}</td>
  </tr>
  <tr>
    <td></td>
    <td>

      Esia

    </td>
    <td>{{form.esia.desc}}</td>
  </tr>
  <tr>
    <td></td>
    <td>Stage</td>
    <td>{{form.esia.stage}}</td>
  </tr>
  <tr>
    <td></td>
    <td>Est Cost</td>
    <td>{{form.esia.estimatedCost}}</td>
  </tr>
  <tr>
    <td></td>
    <td>Financers</td>
    <td>{{form.esia.financers}}</td>
  </tr>
  <tr>
    <td></td>
    <td>

      Permits & authorizations plan

    </td>
    <td>{{form.permitsAuthorization.desc}}</td>
  </tr>
  <tr>
    <td></td>
    <td>Stage</td>
    <td>{{form.permitsAuthorization.stage}}</td>
  </tr>
  <tr>
    <td></td>
    <td>Est Cost</td>
    <td>{{form.permitsAuthorization.estimatedCost}}</td>
  </tr>
  <tr>
    <td></td>
    <td>Financers</td>
    <td>{{form.permitsAuthorization.financers}}</td>
  </tr>
  <tr>
    <td></td>
    <td>

      Permits LEA

    </td>
    <td>{{form.permitsLea.desc}}</td>
  </tr>
  <tr>
    <td></td>
    <td>Stage</td>
    <td>{{form.permitsLea.stage}}</td>
  </tr>
  <tr>
    <td></td>
    <td>Est Cost</td>
    <td>{{form.permitsLea.estimatedCost}}</td>
  </tr>
  <tr>
    <td></td>
    <td>Financers</td>
    <td>{{form.permitsLea.financers}}</td>
  </tr>
  <tr>
    <td></td>
    <td>

      Main design detailed design

    </td>
    <td>{{form.mainDesignDetailedDesign.desc}}</td>
  </tr>
  <tr>
    <td></td>
    <td>Stage</td>
    <td>{{form.mainDesignDetailedDesign.stage}}</td>
  </tr>
  <tr>
    <td></td>
    <td>Est Cost</td>
    <td>{{form.mainDesignDetailedDesign.estimatedCost}}</td>
  </tr>
  <tr>
    <td></td>
    <td>Financers</td>
    <td>{{form.mainDesignDetailedDesign.financers}}</td>
  </tr>

  <tr>
    <td>Construction pre-Construction</td>
    <td></td>
    <td></td>
  </tr>

  <tr>
    <td></td>
    <td>

      Preparation of Tender

    </td>
    <td>{{form.preparationTender.desc}}</td>
  </tr>
  <tr>
    <td></td>
    <td>Stage</td>
    <td>{{form.preparationTender.stage}}</td>
  </tr>
  <tr>
    <td></td>
    <td>Cost-capex</td>
    <td>{{form.preparationTender.costCapex}}</td>
  </tr>
  <tr>
    <td></td>
    <td>Financers</td>
    <td>{{form.preparationTender.financers}}</td>
  </tr>
  <tr>
    <td></td>
    <td>

      Contracting Works

    </td>
    <td>{{form.contractingWorks.desc}}</td>
  </tr>
  <tr>
    <td></td>
    <td>Stage</td>
    <td>{{form.contractingWorks.stage}}</td>
  </tr>
  <tr>
    <td></td>
    <td>Cost-capex</td>
    <td>{{form.contractingWorks.costCapex}}</td>
  </tr>
  <tr>
    <td></td>
    <td>Financers</td>
    <td>{{form.contractingWorks.financers}}</td>
  </tr>
  <tr>
    <td></td>
    <td>

      Contracting of Supervision

    </td>
    <td>{{form.contractingOfSupervision.desc}}</td>
  </tr>
  <tr>
    <td></td>
    <td>Stage</td>
    <td>{{form.contractingOfSupervision.stage}}</td>
  </tr>
  <tr>
    <td></td>
    <td>Cost-capex</td>
    <td>{{form.contractingOfSupervision.costCapex}}</td>
  </tr>
  <tr>
    <td></td>
    <td>Financers</td>
    <td>{{form.contractingOfSupervision.financers}}</td>
  </tr>

  <tr>
    <td></td>
    <td>

      Construction : Works

    </td>
    <td>{{form.contractingOfSupervision.works.desc}}</td>
  </tr>
  <tr>
    <td></td>
    <td>Stage</td>
    <td>{{form.contractingOfSupervision.works.stage}}</td>
  </tr>
  <tr>
    <td></td>
    <td>Estimated Cost</td>
    <td>{{form.contractingOfSupervision.works.estimatedCost}}</td>
  </tr>
  <tr>
    <td></td>
    <td>Financers</td>
    <td>{{form.contractingOfSupervision.works.financers}}</td>
  </tr>
  <tr>
    <td></td>
    <td>

      Construction : Quality Assurance

    </td>
    <td>{{form.contractingOfSupervision.qualityAssurance.desc}}</td>
  </tr>
  <tr>
    <td></td>
    <td>Stage</td>
    <td>{{form.contractingOfSupervision.qualityAssurance.stage}}</td>
  </tr>
  <tr>
    <td></td>
    <td>Estimated Cost</td>
    <td>{{form.contractingOfSupervision.qualityAssurance.estimatedCost}}</td>
  </tr>
  <tr>
    <td></td>
    <td>Financers</td>
    <td>{{form.contractingOfSupervision.qualityAssurance.financers}}</td>
  </tr>


  <tr>
    <td>Operations</td>
    <td></td>
    <td></td>
  </tr>

  <tr>
    <td></td>
    <td>

      Commissioning/Handover

    </td>
    <td>{{form.commisioningHandover.desc}}</td>
  </tr>
  <tr>
    <td></td>
    <td>Estimated Cost</td>
    <td>{{form.commisioningHandover.estCost}}</td>
  </tr>
  <tr>
    <td></td>
    <td>Financers</td>
    <td>{{form.commisioningHandover.financers}}</td>
  </tr>

  <tr>
    <td></td>
    <td>

      Operations

    </td>
    <td>{{form.operations.desc}}</td>
  </tr>
  <tr>
    <td></td>
    <td>Estimated Cost</td>
    <td>{{form.operations.estCost}}</td>
  </tr>
  <tr>
    <td></td>
    <td>Financers</td>
    <td>{{form.operations.financers}}</td>
  </tr>
  <tr>
    <td></td>
    <td>

      Cashflow/control/accounting

    </td>
    <td>{{form.cashflowControlAccounting.desc}}</td>
  </tr>
  <tr>
    <td></td>
    <td>Estimated Cost</td>
    <td>{{form.cashflowControlAccounting.estCost}}</td>
  </tr>
  <tr>
    <td></td>
    <td>Financers</td>
    <td>{{form.cashflowControlAccounting.financers}}</td>
  </tr>
  <tr>
    <td></td>
    <td>

      HR / TAX / LEGAL

    </td>
    <td>{{form.hrTaxLegal.desc}}</td>
  </tr>
  <tr>
    <td></td>
    <td>Estimated Cost</td>
    <td>{{form.hrTaxLegal.estCost}}</td>
  </tr>
  <tr>
    <td></td>
    <td>Financers</td>
    <td>{{form.hrTaxLegal.financers}}</td>
  </tr>
  <tr>
    <td></td>
    <td>

      Control / Supervision / Monitoring

    </td>
    <td>{{form.controlSuperVisionMonitoring.desc}}</td>
  </tr>
  <tr>
    <td></td>
    <td>Estimated Cost</td>
    <td>{{form.controlSuperVisionMonitoring.estCost}}</td>
  </tr>
  <tr>
    <td></td>
    <td>Financers</td>
    <td>{{form.controlSuperVisionMonitoring.financers}}</td>
  </tr>
</table>

<clr-modal [(clrModalOpen)]="basic">
  <div class="modal-body" style="text-align: -webkit-center">
    <div class="clr-col-4 clr-col-sm-4 clr-col-md-4 clr-col-lg-2 clr-col-xl-2 data"
      style="width: 100%; max-width: 300px;padding-right: 0px !important ;">
      <div style="float: right; margin-bottom: -21px;">
        <a href="/assets/info.pdf" target="_blank">
          <i style="font-size: 15px;" class="far fa-question-circle"></i>
        </a>
      </div>

      <clr-tabs>
        <clr-tab>
          <button clrTabLink id="link1">Sub-sector</button>
          <clr-tab-content id="content1" *clrIfActive="true">

            <button class="btn btn-outline btn-block" [class]="subSectorSelection.air ? 'selectedAir' : ''"
              (click)="subSectorSelection.air = !subSectorSelection.air; onFilter()">
              <span style="margin-right: 10px">Air </span>
            </button>

            <button class="btn btn-outline btn-block" [class]="subSectorSelection.maritime ? 'selectedMaritime' : ''"
              (click)="subSectorSelection.maritime = !subSectorSelection.maritime; onFilter()">
              <span style="margin-right: 10px"> Maritime </span>
            </button>
            <button class="btn btn-outline btn-block" [class]="subSectorSelection.road ? 'selectedRoad' : ''"
              (click)="subSectorSelection.road = !subSectorSelection.road; onFilter()">
              <span style="margin-right: 10px"> Road </span>
            </button>
            <button class="btn btn-outline btn-block" [class]="subSectorSelection.rail ? 'selectedRail' : ''"
              (click)="subSectorSelection.rail = !subSectorSelection.rail; onFilter()">
              <span style="margin-right: 10px"> Rail </span>
            </button>


            <!-- {{subSectorSelection | json}} -->
          </clr-tab-content>
        </clr-tab>
        <clr-tab>
          <button clrTabLink (click)="onFilter()">Project Progress</button>
          <clr-tab-content *clrIfActive="false">
            <clr-accordion>
              <clr-accordion-panel (clrAccordionPanelOpenChange)="onFilter()">
                <clr-accordion-title style="font-size: large; font-weight: bolder;"> Planning</clr-accordion-title>
                <clr-accordion-content *clrIfExpanded>
                  <clr-tree>
                    <ng-container *ngFor="let item of planning; let index = index">
                      <clr-tree-node [class]="item.enable ? 'highlited' : ''" style="font-size: medium"
                        (click)="item.enable = !!$event; onFilter(); selectBeforePlanning(index, !!$event);">
                        {{item.name}}
                      </clr-tree-node>
                    </ng-container>
                  </clr-tree>
                </clr-accordion-content>
              </clr-accordion-panel>

              <clr-accordion-panel>
                <clr-accordion-title style="font-size: large; font-weight: bolder;">Construction</clr-accordion-title>
                <clr-accordion-content *clrIfExpanded>
                  <clr-tree>
                    <ng-container *ngFor="let item of construction.slice(0,3); let index = index">
                      <clr-tree-node [class]="item.enable ? 'highlited' : ''" style="font-size: medium"
                        (click)="item.enable = !!$event; onFilter(); selectBeforeConstruction(index, !!$event); ">
                        {{item.name}}
                      </clr-tree-node>

                    </ng-container>
                    <span style="margin-left: 11px; font-size: medium; margin-top: 14px;">
                      {{construction[3].name}} :
                    </span>
                    <clr-tree-node style="margin-left: 20px; font-size: medium;"
                      [class]="construction[3].items[0].enable ? 'highlited' : ''"
                      (click)="construction[3].items[0].enable = !!$event; onFilter(); selectBeforeConstruction(3, !!$event, 0); ">
                      {{construction[3].items[0].name}}
                    </clr-tree-node>
                    <clr-tree-node style="margin-left: 20px; font-size: medium;"
                      [class]="construction[3].items[1].enable ? 'highlited' : ''"
                      (click)="construction[3].items[1].enable = !!$event; onFilter(); selectBeforeConstruction(3, !!$event , 1); ">
                      {{construction[3].items[1].name}}
                    </clr-tree-node>
                  </clr-tree>
                </clr-accordion-content>
              </clr-accordion-panel>
              <clr-accordion-panel>
                <clr-accordion-title style="font-size: large; font-weight: bolder;"> Operations</clr-accordion-title>
                <clr-accordion-content *clrIfExpanded>
                  <clr-tree>
                    <ng-container *ngFor="let item of operations; let index = index">

                      <clr-tree-node [class]="item.enable ? 'highlited' : ''" style="font-size: medium"
                        (click)="item.enable = !!$event; onFilter(); selectBeforeOperations(index, !!$event);">
                        {{item.name}}
                      </clr-tree-node>
                    </ng-container>
                  </clr-tree>
                </clr-accordion-content>
              </clr-accordion-panel>
            </clr-accordion>

            <!-- {{projectProgressSelection | json}} -->

          </clr-tab-content>
        </clr-tab>
      </clr-tabs>

    </div>
  </div>

</clr-modal>