import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { AboutComponent } from './about/about.component';
import { BusinessConnectivityComponent } from './business-connectivity/business-connectivity.component';
import { HomeComponent } from './home/home.component';
import { MapComponent } from './map/map.component';
import { ProjectFormComponent } from './project-form/project-form.component';

const routes: Routes = [
  {
    path: '', component: HomeComponent
  },
  {
    path: 'form', component: ProjectFormComponent
  },
  {
    path: 'map', component: MapComponent
  },
  {
    path: 'home', component: HomeComponent
  },
  {
    path: 'about', component: AboutComponent
  },
  {
    path: 'business', component: BusinessConnectivityComponent
  },
  {
    path: '**', component: HomeComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
