<div class="container" style="padding-top: 10px; padding-left: 30px; padding-right: 30px;">
  <input  type="file" accept=".json" style="display: none;" (change)="onFileChanged($event)" #fileInput>
  <button class="btn" (click)="fileInput.click()">Select File</button>
  <clr-accordion>
    <clr-accordion-panel>
      <clr-accordion-title>General Information</clr-accordion-title>
      <clr-accordion-content *clrIfExpanded>
        <ng-container>
          <clr-input-container>
            <label>Project Title</label>
            <input clrInput type="text" [(ngModel)]="form.projectTitle" name="firstName" required />
          </clr-input-container>

          <div class="clr-form-control">
            <label for="select-error" class="clr-control-label">Sector</label>
            <div class="clr-control-container ">
              <div class="clr-select-wrapper">
                <select id="select-error" class="clr-select" style="width: 200.4px;" [(ngModel)]="form.sector">
                  <option value="Transport">Transport</option>
                  <option value="Energy">Energy</option>
                  <option value="Digital">Digital</option>
                </select>
              </div>
            </div>
          </div>
          <div class="clr-form-control" style="margin-top: 30px">
            <label for="select-error" class="clr-control-label">Sub Sector</label>
            <div class="clr-control-container ">
              <div class="clr-select-wrapper">
                <select id="select-error" class="clr-select" style="width: 200.4px;" [(ngModel)]="form.subSector">
                  <option value="Rail">Rail</option>
                  <option value="Road">Road</option>
                  <option value="Air">Air</option>
                  <option value="Maritime">Maritime</option>
                </select>
              </div>
            </div>
          </div>

          <clr-input-container>
            <label>Corridor Route</label>
            <input clrInput type="text" [(ngModel)]="form.corridor" name="corridor" required />
          </clr-input-container>


          <clr-input-container>
            <label>From</label>
            <input clrInput type="text" [(ngModel)]="form.portionFrom" name="portionFrom" required />
            <!-- <clr-control-error>We need your last name for legal compliance</clr-control-error> -->
          </clr-input-container>
          <clr-input-container>
            <label>To</label>
            <input clrInput type="text" [(ngModel)]="form.portionTo" name="portionTo" required />
            <!-- <clr-control-error>We need your last name for legal compliance</clr-control-error> -->
          </clr-input-container>

          <div class="clr-form-control" style="margin-top: 30px">
            <label for="select-error" class="clr-control-label">Country(ies)</label>
            <div class="clr-control-container ">
              <div class="clr-select-wrapper">
                <select id="select-error" class="clr-select" style="width: 200.4px;" [(ngModel)]="form.country">
                  <option value="Albania">Albania</option>
                  <option value="Italy">Italy</option>
                  <option value="Montenegro">Montenegro</option>
                </select>
              </div>
            </div>
          </div>
          <clr-input-container>
            <label>Other</label>
            <input clrInput type="text" [(ngModel)]="form.other" name="lastName" required />
            <!-- <clr-control-error>We need your last name for legal compliance</clr-control-error> -->
          </clr-input-container>
          <!-- <clr-radio-container clrInline>
            <label class="clr-control-label">Marker</label>
            <clr-radio-wrapper>
              <input type="radio" clrRadio value="true" [(ngModel)]="form.marker" name="marker" />
              <label>Yes</label>
            </clr-radio-wrapper>
            <clr-radio-wrapper>
              <input type="radio" clrRadio value="false" [(ngModel)]="form.marker" name="marker" />
              <label>No</label>
            </clr-radio-wrapper>
          </clr-radio-container> -->

        </ng-container>

      </clr-accordion-content>
    </clr-accordion-panel>

    <clr-accordion-panel>
      <clr-accordion-title>Owner Stakeholders</clr-accordion-title>
      <clr-accordion-content *clrIfExpanded>
        <clr-input-container>
          <label>Owner</label>
          <input clrInput type="text" [(ngModel)]="form.owner" name="firstName" required />
        </clr-input-container>
        <clr-input-container>
          <label>Principal / Project Proponent</label>
          <input clrInput type="text" [(ngModel)]="form.principalProjectProponent" name="firstName" required />
        </clr-input-container>
        <clr-input-container>
          <label>LGU / Municipalities impacted</label>
          <input clrInput type="text" [(ngModel)]="form.LGUMunicipalitiesImpacted" name="firstName" required />
        </clr-input-container>
        <clr-input-container>
          <label>Private (i.e. PPP) </label>
          <input clrInput type="text" [(ngModel)]="form.private" name="firstName" required />
        </clr-input-container>
      </clr-accordion-content>
    </clr-accordion-panel>

    <clr-accordion-panel>
      <clr-accordion-title> Project Definition / Regional Dimension</clr-accordion-title>
      <clr-accordion-content *clrIfExpanded>
        <clr-textarea-container>
          <label>Strategic Relevance</label>
          <textarea clrTextarea [(ngModel)]="form.strategicRelevance" name="strategicRelevance" required></textarea>
        </clr-textarea-container>

        <clr-textarea-container>
          <label>General description </label>
          <textarea clrTextarea [(ngModel)]="form.generalDescription" name="generalDescription" required></textarea>
        </clr-textarea-container>

        <clr-input-container>
          <label>Commercial set up & business development </label>
          <input clrInput type="text" [(ngModel)]="form.commercialSetUpBusinessDevelopment"
            name="commercialSetUpBusinessDevelopment" required />
        </clr-input-container>
        <br>
        <label class="clr-control-label">Technical data (length, etc) </label>
        <div style="margin-left: 35px">

          <ng-container *ngIf='form.subSector === "Rail"'>
            <clr-input-container>
              <label>Length (km)</label>
              <input clrInput type="text" [(ngModel)]="form.lengthOfStreet" name="lengthOfStreet" required
                style="margin-left: 19px;" />
            </clr-input-container>
            <clr-input-container>
              <label>Max design speed (km/Hrs)</label>
              <input clrInput type="text" [(ngModel)]="form.maxDesignSpeed" name="maxDesignSpeed" required
                style="margin-left: 19px;" />
            </clr-input-container>
            <clr-input-container>
              <label>Freight axle load (t)</label>
              <input clrInput type="text" [(ngModel)]="form.freightAxleLoad" name="freightAxleLoad" required
                style="margin-left: 19px;" />
            </clr-input-container>
            <clr-input-container>
              <label>Max train lines</label>
              <input clrInput type="text" [(ngModel)]="form.maxTrainLines" name="maxTrainLines" required
                style="margin-left: 19px;" />
            </clr-input-container>
          </ng-container>

          <ng-container *ngIf='form.subSector === "Road"'>
            <clr-input-container>
              <label>Length (km)</label>
              <input clrInput type="text" [(ngModel)]="form.lengthOfStreet" name="lengthOfStreet" required
                style="margin-left: 19px;" />
            </clr-input-container>
            <clr-input-container>
              <label>Max design speed </label>
              <input clrInput type="text" [(ngModel)]="form.maxDesignSpeed" name="maxDesignSpeed" required
                style="margin-left: 19px;" />
            </clr-input-container>
            <clr-input-container>
              <label> No of traffic lanes per direction </label>
              <input clrInput type="text" [(ngModel)]="form.noOfTrafficLanes" name="noOfTrafficLanes" required
                style="margin-left: 19px;" />
            </clr-input-container>

            <clr-radio-container clrInline>
              <label class="clr-control-label">Existence of emergency lanes</label>
              <clr-radio-wrapper>
                <input type="radio" clrRadio value="true" [(ngModel)]="form.emergencyLanes" name="emergencyLanes" />
                <label>Yes</label>
              </clr-radio-wrapper>
              <clr-radio-wrapper>
                <input type="radio" clrRadio value="false" [(ngModel)]="form.emergencyLanes" name="emergencyLanes" />
                <label>No</label>
              </clr-radio-wrapper>
            </clr-radio-container>


            <clr-radio-container clrInline>
              <label class="clr-control-label">Tolling system</label>
              <clr-radio-wrapper>
                <input type="radio" clrRadio value="true" [(ngModel)]="form.tollingSystem" name="tollingSystem" />
                <label>Yes</label>
              </clr-radio-wrapper>
              <clr-radio-wrapper>
                <input type="radio" clrRadio value="false" [(ngModel)]="form.tollingSystem" name="tollingSystem" />
                <label>No</label>
              </clr-radio-wrapper>
            </clr-radio-container>
            <clr-radio-container clrInline>
              <label class="clr-control-label">Intelligent transport systems</label>
              <clr-radio-wrapper>
                <input type="radio" clrRadio value="true" [(ngModel)]="form.intelligentTransportSystem"
                  name="intelligentTransportSystem" />
                <label>Yes</label>
              </clr-radio-wrapper>
              <clr-radio-wrapper>
                <input type="radio" clrRadio value="false" [(ngModel)]="form.intelligentTransportSystem"
                  name="intelligentTransportSystem" />
                <label>No</label>
              </clr-radio-wrapper>
            </clr-radio-container>
          </ng-container>

          <ng-container *ngIf='form.subSector === "Maritime"'>
            <clr-input-container>
              <label>Cargo capacity</label>
              <input clrInput type="text" [(ngModel)]="form.cargoCapacity" name="cargoCapacity" required />
            </clr-input-container>
            <clr-input-container>
              <label>Bearing capacity</label>
              <input clrInput type="text" [(ngModel)]="form.bearingCapacity" name="bearingCapacity" required />
            </clr-input-container>
            <clr-input-container>
              <label> No of traffic lanes per direction </label>
              <input clrInput type="text" [(ngModel)]="form.noOfTrafficLanes" name="noOfTrafficLanes" required
                style="margin-left: 19px;" />
            </clr-input-container>
          </ng-container>

          <ng-container *ngIf='form.subSector === "Air"'>
            <clr-input-container>
              <label>Cargo capacity</label>
              <input clrInput type="text" [(ngModel)]="form.cargoCapacity" name="cargoCapacity" required />
            </clr-input-container>
            <clr-input-container>
              <label>Bearing capacity</label>
              <input clrInput type="text" [(ngModel)]="form.bearingCapacity" name="bearingCapacity" required />
            </clr-input-container>
            <clr-input-container>
              <label> No of traffic lanes per direction </label>
              <input clrInput type="text" [(ngModel)]="form.noOfTrafficLanes" name="noOfTrafficLanes" required
                style="margin-left: 19px;" />
            </clr-input-container>
          </ng-container>



          <clr-radio-container clrInline>
            <label class="clr-control-label">SEETO</label>
            <clr-radio-wrapper>
              <input type="radio" clrRadio value="true" [(ngModel)]="form.seeto" name="seeto" />
              <label>Yes</label>
            </clr-radio-wrapper>
            <clr-radio-wrapper>
              <input type="radio" clrRadio value="false" [(ngModel)]="form.seeto" name="seeto" />
              <label>No</label>
            </clr-radio-wrapper>
          </clr-radio-container>
          <clr-radio-container clrInline>
            <label class="clr-control-label">ENC</label>
            <clr-radio-wrapper>
              <input type="radio" clrRadio value="true" [(ngModel)]="form.enc" name="enc" />
              <label>Yes</label>
            </clr-radio-wrapper>
            <clr-radio-wrapper>
              <input type="radio" clrRadio value="false" [(ngModel)]="form.enc" name="enc" />
              <label>No</label>
            </clr-radio-wrapper>
          </clr-radio-container>
          <clr-radio-container clrInline>
            <label class="clr-control-label">BRI</label>
            <clr-radio-wrapper>
              <input type="radio" clrRadio value="true" [(ngModel)]="form.bri" name="bri" />
              <label>Yes</label>
            </clr-radio-wrapper>
            <clr-radio-wrapper>
              <input type="radio" clrRadio value="false" [(ngModel)]="form.bri" name="bri" />
              <label>No</label>
            </clr-radio-wrapper>
          </clr-radio-container>
          <clr-input-container>
            <label>Other </label>
            <input clrInput type="text" [(ngModel)]="form.otherProjectDef" name="otherProjectDef"
              style="margin-left: 19px;" />
          </clr-input-container>
        </div>
        <clr-input-container>
          <label>Estimated RoI </label>
          <input clrInput type="text" [(ngModel)]="form.estimatedRoI" name="estimatedRoI" required />
        </clr-input-container>

        <clr-textarea-container>
          <label>Estimated Impact </label>
          <textarea clrTextarea [(ngModel)]="form.estimatedImpact" name="estimatedImpact" required></textarea>
        </clr-textarea-container>


        <clr-input-container>
          <label>Total cost est. (Capex & Opex) </label>
          <input clrInput type="text" [(ngModel)]="form.totalCostEstCapexOpex" name="totalCostEstCapexOpex" required />
        </clr-input-container>
      </clr-accordion-content>
    </clr-accordion-panel>

    <clr-accordion-panel>
      <clr-accordion-title> Planning</clr-accordion-title>
      <clr-accordion-content *clrIfExpanded>
        <label class="clr-control-label" style="margin-top: 15px;">Pre-feasibility </label>
        <div style="margin-left: 35px">

          <clr-textarea-container>
            <textarea clrTextarea [(ngModel)]="form.preFeasability.desc" name="preFeasability.desc" required></textarea>
          </clr-textarea-container>

          <clr-radio-container clrInline>
            <label class="clr-control-label">Stage</label>
            <clr-radio-wrapper>
              <input type="radio" clrRadio value="true" [(ngModel)]="form.preFeasability.stage"
                name="preFeasabilitystage" />
              <label>Yes</label>
            </clr-radio-wrapper>
            <clr-radio-wrapper>
              <input type="radio" clrRadio value="false" [(ngModel)]="form.preFeasability.stage"
                name="preFeasabilitystage" />
              <label>No</label>
            </clr-radio-wrapper>
          </clr-radio-container>
          <clr-input-container>
            <label>Est Cost </label>
            <input clrInput type="text" [(ngModel)]="form.preFeasability.estimatedCost"
              name="preFeasabilityestimatedCost" style="margin-left: 19px;" />
          </clr-input-container>
          <clr-input-container>
            <label>Financers </label>
            <input clrInput type="text" [(ngModel)]="form.preFeasability.financers" name="preFeasabilityFinancers"
              style="margin-left: 19px;" />
          </clr-input-container>
        </div>
        <label class="clr-control-label" style="margin-top: 15px;">Conceptual Design </label>
        <div style="margin-left: 35px">
          <clr-textarea-container>
            <textarea clrTextarea [(ngModel)]="form.conceptualDesign.desc" name="conceptualDesign.desc"
              required></textarea>
          </clr-textarea-container>
          <clr-radio-container clrInline>
            <label class="clr-control-label">Stage</label>
            <clr-radio-wrapper>
              <input type="radio" clrRadio value="true" [(ngModel)]="form.conceptualDesign.stage"
                name="conceptualDesignstage" />
              <label>Yes</label>
            </clr-radio-wrapper>
            <clr-radio-wrapper>
              <input type="radio" clrRadio value="false" [(ngModel)]="form.conceptualDesign.stage"
                name="conceptualDesignstage" />
              <label>No</label>
            </clr-radio-wrapper>
          </clr-radio-container>
          <clr-input-container>
            <label>Est Cost </label>
            <input clrInput type="text" [(ngModel)]="form.conceptualDesign.estimatedCost"
              name="conceptualDesignestimatedCost" style="margin-left: 19px;" />
          </clr-input-container>
          <clr-input-container>
            <label>Financers </label>
            <input clrInput type="text" [(ngModel)]="form.conceptualDesign.financers" name="conceptualDesignFinancers"
              style="margin-left: 19px;" />
          </clr-input-container>
        </div>
        <label class="clr-control-label" style="margin-top: 15px;">Feasibility study </label>
        <div style="margin-left: 35px">
          <clr-textarea-container>
            <textarea clrTextarea [(ngModel)]="form.feasibilityStudy.desc" name="feasibilityStudy.desc"
              required></textarea>
          </clr-textarea-container>
          <clr-radio-container clrInline>
            <label class="clr-control-label">Stage</label>
            <clr-radio-wrapper>
              <input type="radio" clrRadio value="true" [(ngModel)]="form.feasibilityStudy.stage"
                name="feasibilityStudystage" />
              <label>Yes</label>
            </clr-radio-wrapper>
            <clr-radio-wrapper>
              <input type="radio" clrRadio value="false" [(ngModel)]="form.feasibilityStudy.stage"
                name="feasibilityStudystage" />
              <label>No</label>
            </clr-radio-wrapper>
          </clr-radio-container>
          <clr-input-container>
            <label>Est Cost </label>
            <input clrInput type="text" [(ngModel)]="form.feasibilityStudy.estimatedCost"
              name="feasibilityStudyestimatedCost" style="margin-left: 19px;" />
          </clr-input-container>
          <clr-input-container>
            <label>Financers </label>
            <input clrInput type="text" [(ngModel)]="form.feasibilityStudy.financers" name="feasibilityStudyFinancers"
              style="margin-left: 19px;" />
          </clr-input-container>
        </div>
        <label class="clr-control-label" style="margin-top: 15px;">Preliminary design </label>
        <div style="margin-left: 35px">
          <clr-textarea-container>
            <textarea clrTextarea [(ngModel)]="form.preliminaryDesign.desc" name="preliminaryDesign.desc"
              required></textarea>
          </clr-textarea-container>
          <clr-radio-container clrInline>
            <label class="clr-control-label">Stage</label>
            <clr-radio-wrapper>
              <input type="radio" clrRadio value="true" [(ngModel)]="form.preliminaryDesign.stage"
                name="preliminaryDesignstage" />
              <label>Yes</label>
            </clr-radio-wrapper>
            <clr-radio-wrapper>
              <input type="radio" clrRadio value="false" [(ngModel)]="form.preliminaryDesign.stage"
                name="preliminaryDesignstage" />
              <label>No</label>
            </clr-radio-wrapper>
          </clr-radio-container>
          <clr-input-container>
            <label>Est Cost </label>
            <input clrInput type="text" [(ngModel)]="form.preliminaryDesign.estimatedCost"
              name="preliminaryDesignestimatedCost" style="margin-left: 19px;" />
          </clr-input-container>
          <clr-input-container>
            <label>Financers </label>
            <input clrInput type="text" [(ngModel)]="form.preliminaryDesign.financers" name="preliminaryDesignFinancers"
              style="margin-left: 19px;" />
          </clr-input-container>
        </div>
        <label class="clr-control-label" style="margin-top: 15px;">Esia </label>
        <div style="margin-left: 35px">
          <clr-textarea-container>
            <textarea clrTextarea [(ngModel)]="form.esia.desc" name="esia.desc" required></textarea>
          </clr-textarea-container>
          <clr-radio-container clrInline>
            <label class="clr-control-label">Stage</label>
            <clr-radio-wrapper>
              <input type="radio" clrRadio value="true" [(ngModel)]="form.esia.stage" name="esiastage" />
              <label>Yes</label>
            </clr-radio-wrapper>
            <clr-radio-wrapper>
              <input type="radio" clrRadio value="false" [(ngModel)]="form.esia.stage" name="esiastage" />
              <label>No</label>
            </clr-radio-wrapper>
          </clr-radio-container>
          <clr-input-container>
            <label>Est Cost </label>
            <input clrInput type="text" [(ngModel)]="form.esia.estimatedCost" name="esiaestimatedCost"
              style="margin-left: 19px;" />
          </clr-input-container>
          <clr-input-container>
            <label>Financers </label>
            <input clrInput type="text" [(ngModel)]="form.esia.financers" name="esiaFinancers"
              style="margin-left: 19px;" />
          </clr-input-container>
        </div>
        <label class="clr-control-label" style="margin-top: 15px;">Permits & authorizations plan </label>
        <div style="margin-left: 35px">
          <clr-textarea-container>
            <textarea clrTextarea [(ngModel)]="form.permitsAuthorization.desc" name="permitsAuthorization.desc"
              required></textarea>
          </clr-textarea-container>
          <clr-radio-container clrInline>
            <label class="clr-control-label">Stage</label>
            <clr-radio-wrapper>
              <input type="radio" clrRadio value="true" [(ngModel)]="form.permitsAuthorization.stage"
                name="permitsAuthorizationstage" />
              <label>Yes</label>
            </clr-radio-wrapper>
            <clr-radio-wrapper>
              <input type="radio" clrRadio value="false" [(ngModel)]="form.permitsAuthorization.stage"
                name="permitsAuthorizationstage" />
              <label>No</label>
            </clr-radio-wrapper>
          </clr-radio-container>
          <clr-input-container>
            <label>Est Cost </label>
            <input clrInput type="text" [(ngModel)]="form.permitsAuthorization.estimatedCost"
              name="permitsAuthorizationestimatedCost" style="margin-left: 19px;" />
          </clr-input-container>
          <clr-input-container>
            <label>Financers </label>
            <input clrInput type="text" [(ngModel)]="form.permitsAuthorization.financers"
              name="permitsAuthorizationFinancers" style="margin-left: 19px;" />
          </clr-input-container>
        </div>
        <label class="clr-control-label" style="margin-top: 15px;">Permits LEA</label>
        <div style="margin-left: 35px">
          <clr-textarea-container>
            <textarea clrTextarea [(ngModel)]="form.permitsLea.desc" name="permitsLea.desc" required></textarea>
          </clr-textarea-container>
          <clr-radio-container clrInline>
            <label class="clr-control-label">Stage</label>
            <clr-radio-wrapper>
              <input type="radio" clrRadio value="true" [(ngModel)]="form.permitsLea.stage" name="permitsLeastage" />
              <label>Yes</label>
            </clr-radio-wrapper>
            <clr-radio-wrapper>
              <input type="radio" clrRadio value="false" [(ngModel)]="form.permitsLea.stage" name="permitsLeastage" />
              <label>No</label>
            </clr-radio-wrapper>
          </clr-radio-container>
          <clr-input-container>
            <label>Est Cost </label>
            <input clrInput type="text" [(ngModel)]="form.permitsLea.estimatedCost" name="permitsLeaestimatedCost"
              style="margin-left: 19px;" />
          </clr-input-container>
          <clr-input-container>
            <label>Financers </label>
            <input clrInput type="text" [(ngModel)]="form.permitsLea.financers" name="permitsLeaFinancers"
              style="margin-left: 19px;" />
          </clr-input-container>
        </div>
        <label class="clr-control-label" style="margin-top: 15px;">Main design detailed design </label>
        <div style="margin-left: 35px">
          <clr-textarea-container>
            <textarea clrTextarea [(ngModel)]="form.mainDesignDetailedDesign.desc" name="mainDesignDetailedDesign.desc"
              required></textarea>
          </clr-textarea-container>
          <clr-radio-container clrInline>
            <label class="clr-control-label">Stage</label>
            <clr-radio-wrapper>
              <input type="radio" clrRadio value="true" [(ngModel)]="form.mainDesignDetailedDesign.stage"
                name="mainDesignDetailedDesignstage" />
              <label>Yes</label>
            </clr-radio-wrapper>
            <clr-radio-wrapper>
              <input type="radio" clrRadio value="false" [(ngModel)]="form.mainDesignDetailedDesign.stage"
                name="mainDesignDetailedDesignstage" />
              <label>No</label>
            </clr-radio-wrapper>
          </clr-radio-container>
          <clr-input-container>
            <label>Est Cost </label>
            <input clrInput type="text" [(ngModel)]="form.mainDesignDetailedDesign.estimatedCost"
              name="mainDesignDetailedDesignestimatedCost" style="margin-left: 19px;" />
          </clr-input-container>
          <clr-input-container>
            <label>Financers </label>
            <input clrInput type="text" [(ngModel)]="form.mainDesignDetailedDesign.financers"
              name="mainDesignDetailedDesignFinancers" style="margin-left: 19px;" />
          </clr-input-container>
        </div>
      </clr-accordion-content>
    </clr-accordion-panel>

    <clr-accordion-panel>
      <clr-accordion-title> Construction pre-Construction</clr-accordion-title>
      <clr-accordion-content *clrIfExpanded>
        <label class="clr-control-label" style="margin-top: 15px;">Preparation of Tender </label>
        <div style="margin-left: 35px">
          <clr-textarea-container>
            <textarea clrTextarea [(ngModel)]="form.preparationTender.desc" name="preparationTender.desc"
              required></textarea>
          </clr-textarea-container>
          <clr-radio-container clrInline>
            <label class="clr-control-label">Stage</label>
            <clr-radio-wrapper>
              <input type="radio" clrRadio value="true" [(ngModel)]="form.preparationTender.stage"
                name="preparationTenderstage" />
              <label>Yes</label>
            </clr-radio-wrapper>
            <clr-radio-wrapper>
              <input type="radio" clrRadio value="false" [(ngModel)]="form.preparationTender.stage"
                name="preparationTenderstage" />
              <label>No</label>
            </clr-radio-wrapper>
          </clr-radio-container>
          <clr-input-container>
            <label>Cost-capex </label>
            <input clrInput type="text" [(ngModel)]="form.preparationTender.costCapex"
              name="preparationTenderestimatedCost" style="margin-left: 19px;" />
          </clr-input-container>
          <clr-input-container>
            <label>Financers </label>
            <input clrInput type="text" [(ngModel)]="form.preparationTender.financers" name="preparationTenderFinancers"
              style="margin-left: 19px;" />
          </clr-input-container>
        </div>
        <label class="clr-control-label" style="margin-top: 15px;">Contracting Works </label>
        <div style="margin-left: 35px">
          <clr-textarea-container>
            <textarea clrTextarea [(ngModel)]="form.contractingWorks.desc" name="contractingWorks.desc"
              required></textarea>
          </clr-textarea-container>
          <clr-radio-container clrInline>
            <label class="clr-control-label">Stage</label>
            <clr-radio-wrapper>
              <input type="radio" clrRadio value="true" [(ngModel)]="form.contractingWorks.stage"
                name="contractingWorksstage" />
              <label>Yes</label>
            </clr-radio-wrapper>
            <clr-radio-wrapper>
              <input type="radio" clrRadio value="false" [(ngModel)]="form.contractingWorks.stage"
                name="contractingWorksstage" />
              <label>No</label>
            </clr-radio-wrapper>
          </clr-radio-container>
          <clr-input-container>
            <label>Cost-capex </label>
            <input clrInput type="text" [(ngModel)]="form.contractingWorks.costCapex"
              name="contractingWorksestimatedCost" style="margin-left: 19px;" />
          </clr-input-container>
          <clr-input-container>
            <label>Financers </label>
            <input clrInput type="text" [(ngModel)]="form.contractingWorks.financers" name="contractingWorksFinancers"
              style="margin-left: 19px;" />
          </clr-input-container>
        </div>
        <label class="clr-control-label" style="margin-top: 15px;">Contracting of Supervision </label>
        <div style="margin-left: 35px">
          <clr-textarea-container>
            <textarea clrTextarea [(ngModel)]="form.contractingOfSupervision.desc" name="contractingOfSupervision.desc"
              required></textarea>
          </clr-textarea-container>
          <clr-radio-container clrInline>
            <label class="clr-control-label">Stage</label>
            <clr-radio-wrapper>
              <input type="radio" clrRadio value="true" [(ngModel)]="form.contractingOfSupervision.stage"
                name="contractingOfSupervisionStage" />
              <label>Yes</label>
            </clr-radio-wrapper>
            <clr-radio-wrapper>
              <input type="radio" clrRadio value="false" [(ngModel)]="form.contractingOfSupervision.stage"
                name="contractingOfSupervisionStage" />
              <label>No</label>
            </clr-radio-wrapper>
          </clr-radio-container>
          <clr-input-container>
            <label>Cost-capex </label>
            <input clrInput type="text" [(ngModel)]="form.contractingOfSupervision.costCapex"
              name="contractingOfSupervisionestimatedCost" style="margin-left: 19px;" />
          </clr-input-container>
          <clr-input-container>
            <label>Financers </label>
            <input clrInput type="text" [(ngModel)]="form.contractingOfSupervision.financers"
              name="contractingOfSupervisionFinancers" style="margin-left: 19px;" />
          </clr-input-container>
        </div>
        <label class="clr-control-label" style="margin-top: 15px;">Construction </label>
        <label class="clr-control-label" style="margin-left:35px; margin-top: 10px">Works</label>
        <div style="margin-left:70px; margin-top: 0px;">
          <clr-textarea-container>
            <textarea clrTextarea [(ngModel)]="form.contractingOfSupervision.works.desc"
              name="contractingOfSupervision.works.desc" required></textarea>
          </clr-textarea-container>
          <clr-radio-container clrInline>
            <label class="clr-control-label"> Stage</label>
            <clr-radio-wrapper>
              <input type="radio" clrRadio value="true" [(ngModel)]="form.contractingOfSupervision.works.stage"
                name="contractingOfSupervisionWorkStage" />
              <label>Yes</label>
            </clr-radio-wrapper>
            <clr-radio-wrapper>
              <input type="radio" clrRadio value="false" [(ngModel)]="form.contractingOfSupervision.works.stage"
                name="contractingOfSupervisionWorkStage" />
              <label>No</label>
            </clr-radio-wrapper>
          </clr-radio-container>
          <clr-input-container>
            <label>Cost-capex </label>
            <input clrInput type="text" [(ngModel)]="form.contractingOfSupervision.works.costCapex"
              name="contractingOfSupervisionWorkEstimatedCost" style="margin-left: 19px;" />
          </clr-input-container>
          <clr-input-container>
            <label>Financers </label>
            <input clrInput type="text" [(ngModel)]="form.contractingOfSupervision.works.financers"
              name="contractingOfSupervisionWorkFinancers" style="margin-left: 19px;" />
          </clr-input-container>
        </div>
        <label class="clr-control-label" style="margin-left:35px; margin-top: 10px">Quality Assurance</label>
        <div style="margin-left:70px; margin-top: 0px;">
          <clr-textarea-container>
            <textarea clrTextarea [(ngModel)]="form.contractingOfSupervision.qualityAssurance.desc"
              name="contractingOfSupervision.qualityAssurance.desc" required></textarea>
          </clr-textarea-container>
          <clr-radio-container clrInline>
            <label class="clr-control-label"> Stage</label>
            <clr-radio-wrapper>
              <input type="radio" clrRadio value="true"
                [(ngModel)]="form.contractingOfSupervision.qualityAssurance.stage"
                name="contractingOfSupervisionQualityAssuranceStage" />
              <label>Yes</label>
            </clr-radio-wrapper>
            <clr-radio-wrapper>
              <input type="radio" clrRadio value="false"
                [(ngModel)]="form.contractingOfSupervision.qualityAssurance.stage"
                name="contractingOfSupervisionQualityAssuranceStage" />
              <label>No</label>
            </clr-radio-wrapper>
          </clr-radio-container>
          <clr-input-container>
            <label>Cost-capex </label>
            <input clrInput type="text" [(ngModel)]="form.contractingOfSupervision.qualityAssurance.costCapex"
              name="contractingOfSupervisionQualityAssuranceEstimatedCost" style="margin-left: 19px;" />
          </clr-input-container>
          <clr-input-container>
            <label>Financers </label>
            <input clrInput type="text" [(ngModel)]="form.contractingOfSupervision.qualityAssurance.financers"
              name="contractingOfSupervisionQualityAssuranceFinancers" style="margin-left: 19px;" />
          </clr-input-container>
        </div>
      </clr-accordion-content>
    </clr-accordion-panel>

    <clr-accordion-panel>
      <clr-accordion-title> Operations</clr-accordion-title>
      <clr-accordion-content *clrIfExpanded>
        <br>
        <label class="clr-control-label"> Commissioning/Handover </label>
        <div style="margin-left: 35px">
          <clr-textarea-container>
            <textarea clrTextarea [(ngModel)]="form.commisioningHandover.desc" name="commisioningHandover.desc"
              required></textarea>
          </clr-textarea-container>
          <clr-input-container>
            <label>Estimated Cost </label>
            <input clrInput type="text" [(ngModel)]="form.commisioningHandover.estCost"
              name="commisioningHandoverEstCost" required style="margin-left: 19px;" />
          </clr-input-container>
        </div>
        <div style="margin-left: 35px">
          <clr-input-container>
            <label>Financers </label>
            <input clrInput type="text" [(ngModel)]="form.commisioningHandover.financers"
              name="commisioningHandoverFinancers" required style="margin-left: 19px;" />
          </clr-input-container>
        </div>
        <br>
        <label class="clr-control-label"> Operations </label>
        <div style="margin-left: 35px">
          <clr-textarea-container>
            <textarea clrTextarea [(ngModel)]="form.operations.desc" name="operations.desc" required></textarea>
          </clr-textarea-container>
          <clr-input-container>
            <label>Estimated Cost </label>
            <input clrInput type="text" [(ngModel)]="form.operations.estCost" name="operationsEstCost" required
              style="margin-left: 19px;" />
          </clr-input-container>
        </div>
        <div style="margin-left: 35px">
          <clr-input-container>
            <label>Financers </label>
            <input clrInput type="text" [(ngModel)]="form.operations.financers" name="operationsFinancers" required
              style="margin-left: 19px;" />
          </clr-input-container>
        </div>
        <br>
        <label class="clr-control-label"> Cashflow/control/accounting </label>
        <div style="margin-left: 35px">
          <clr-textarea-container>
            <textarea clrTextarea [(ngModel)]="form.cashflowControlAccounting.desc"
              name="cashflowControlAccounting.desc" required></textarea>
          </clr-textarea-container>
          <clr-input-container>
            <label>Estimated Cost </label>
            <input clrInput type="text" [(ngModel)]="form.cashflowControlAccounting.estCost"
              name="cashflowControlAccountingEstCost" required style="margin-left: 19px;" />
          </clr-input-container>
        </div>
        <div style="margin-left: 35px">
          <clr-input-container>
            <label>Financers </label>
            <input clrInput type="text" [(ngModel)]="form.cashflowControlAccounting.financers"
              name="cashflowControlAccountingFinancers" required style="margin-left: 19px;" />
          </clr-input-container>
        </div>
        <br>
        <label class="clr-control-label"> HR / TAX / LEGAL </label>
        <div style="margin-left: 35px">
          <clr-textarea-container>
            <textarea clrTextarea [(ngModel)]="form.hrTaxLegal.desc" name="hrTaxLegal.desc" required></textarea>
          </clr-textarea-container>
          <clr-input-container>
            <label>Estimated Cost </label>
            <input clrInput type="text" [(ngModel)]="form.hrTaxLegal.estCost" name="hrTaxLegalEstCost" required
              style="margin-left: 19px;" />
          </clr-input-container>
        </div>
        <div style="margin-left: 35px">
          <clr-input-container>
            <label>Financers </label>
            <input clrInput type="text" [(ngModel)]="form.hrTaxLegal.financers" name="hrTaxLegalFinancers" required
              style="margin-left: 19px;" />
          </clr-input-container>
        </div>
        <br>
        <label class="clr-control-label"> Control / Supervision / Monitoring</label>

        <div style="margin-left: 35px">
          <clr-textarea-container>
            <textarea clrTextarea [(ngModel)]="form.controlSuperVisionMonitoring.desc"
              name="controlSuperVisionMonitoring.desc" required></textarea>
          </clr-textarea-container>
          <clr-input-container>
            <label>Estimated Cost </label>
            <input clrInput type="text" [(ngModel)]="form.controlSuperVisionMonitoring.estCost"
              name="controlSuperVisionMonitoringEstCost" required style="margin-left: 19px;" />
          </clr-input-container>
        </div>
        <div style="margin-left: 35px">
          <clr-input-container>
            <label>Financers </label>
            <input clrInput type="text" [(ngModel)]="form.controlSuperVisionMonitoring.financers"
              name="controlSuperVisionMonitoringFinancers" required style="margin-left: 19px;" />
          </clr-input-container>
        </div>
      </clr-accordion-content>
    </clr-accordion-panel>
  </clr-accordion>

  <div leaflet style="min-height: 400px; height: 100%;" leafletDraw [leafletOptions]="options"
    [leafletDrawOptions]="drawOptions" (leafletDrawCreated)="onDrawCreated($event)">

    <div [leafletLayer]="drawItems"></div>

  </div>

  <button class="btn btn-primary" (click)="downloadJson()">EXPORT JSON</button>
  <!-- <button class="btn btn-primary" (click)="printPDF()">DOWNLOAD JSON</button> -->

</div>
<!-- <ng-container *ngIf="printTable"> -->
<br>
<!-- <table id="simple_table" style="display: none;">
  <tr>
    <th>Field</th>
    <th>Value</th>
  </tr>
  <h2>
    General Information
  </h2>
  <tr>
    <td>Project Title</td>
    <td>{{form.projectTitle}}</td>
  </tr>
  <tr>
    <td>Sector</td>
    <td>{{form.sector}}</td>
  </tr>
  <tr>
    <td>Sub - Sector</td>
    <td>{{form.subSector}}</td>
  </tr>
  <tr>
    <td>Corridor Route</td>
    <td>{{form.corridor}}</td>
  </tr>
  <tr>
    <td>From</td>
    <td>{{form.portionFrom}}</td>
  </tr>
  <tr>
    <td>To</td>
    <td>{{form.portionTo}}</td>
  </tr>
  <tr>
    <td>Country(ies)</td>
    <td>{{form.country}}</td>
  </tr>
  <tr>
    <td>Other</td>
    <td>{{form.other}}</td>
  </tr>
  <h2>
    Owner Stakeholders
  </h2>
  <tr>
    <td>Owner</td>
    <td>{{form.owner}}</td>
  </tr>
  <tr>
    <td>Principal / Project Proponent</td>
    <td>{{form.principalProjectProponent}}</td>
  </tr>
  <tr>
    <td>LGU / Municipalities impacted</td>
    <td>{{form.LGUMunicipalitiesImpacted}}</td>
  </tr>
  <tr>
    <td>Private (i.e. PPP) </td>
    <td>{{form.private}}</td>
  </tr>
  <h2>
    Project Definition / Regional Dimension
  </h2>
  <tr>
    <td>Strategic Relevance</td>
    <td>{{form.strategicRelevance}}</td>
  </tr>
  <tr>
    <td>General description </td>
    <td>{{form.generalDescription}}</td>
  </tr>
  <tr>
    <td>Commercial set up & business development </td>
    <td>{{form.commercialSetUpBusinessDevelopment}}</td>
  </tr>
  <tr>
    <strong>
      Technical data (length, etc)
    </strong>
  </tr>
  <div id="subSector_rail" style="display: none;">
    <tr>
      <td>Length (km)</td>
      <td>{{form.lengthOfStreet}}</td>
    </tr>
    <tr>
      <td>Max design speed (km/Hrs)</td>
      <td>{{form.maxDesignSpeed}}</td>
    </tr>
    <tr>
      <td>Freight axle load (t)</td>
      <td>{{form.freightAxleLoad}}</td>
    </tr>
    <tr>
      <td>Max train lines</td>
      <td>{{form.maxTrainLines}}</td>
    </tr>
  </div>
  <div id="subSector_road" style="display: none;">
    <tr>
      <td>Length (km)</td>
      <td>{{form.lengthOfStreet}}</td>
    </tr>
    <tr>
      <td>Max design speed </td>
      <td>{{form.maxDesignSpeed}}</td>
    </tr>
    <tr>
      <td> No of traffic lanes per direction </td>
      <td>{{form.noOfTrafficLanes}}</td>
    </tr>
    <tr>
      <td>Existence of emergency lanes</td>
      <td>{{form.emergencyLanes}}</td>
    </tr>
    <tr>
      <td>Tolling system</td>
      <td>{{form.tollingSystem}}</td>
    </tr>
    <tr>
      <td>Intelligent transport systems </td>
      <td>{{form.intelligentTransportSystem}}</td>
    </tr>
  </div>
  <div id="subSector_maritime" style="display: none;">
    <tr>
      <td>Cargo capacity</td>
      <td>{{form.cargoCapacity}}</td>
    </tr>
    <tr>
      <td>Bearing capacity</td>
      <td>{{form.bearingCapacity}}</td>
    </tr>
    <tr>
      <td> No of traffic lanes per direction </td>
      <td>{{form.noOfTrafficLanes}}</td>
    </tr>
  </div>
  <div id="subSector_air" style="display: none;">
    <tr>
      <td>Cargo capacity</td>
      <td>{{form.cargoCapacity}}</td>
    </tr>
    <tr>
      <td>Bearing capacity</td>
      <td>{{form.bearingCapacity}}</td>
    </tr>
    <tr>
      <td> No of traffic lanes per direction </td>
      <td>{{form.noOfTrafficLanes}}</td>
    </tr>
  </div>
  <tr>
    <td>SEETO</td>
    <td>{{form.seeto}}</td>
  </tr>
  <tr>
    <td>ENC</td>
    <td>{{form.enc}}</td>
  </tr>
  <tr>
    <td>BRI</td>
    <td>{{form.bri}}</td>
  </tr>
  <tr>
    <td>Other</td>
    <td>{{form.otherProjectDef}}</td>
  </tr>
  <tr>
    <td>Estimated RoI</td>
    <td>{{form.estimatedRoI}}</td>
  </tr>
  <tr>
    <td>Estimated Impact</td>
    <td>{{form.estimatedImpact}}</td>
  </tr>
  <tr>
    <td>Total cost est. (Capex & Opex)</td>
    <td>{{form.totalCostEstCapexOpex}}</td>
  </tr>

  <h2>Planning</h2>
  <tr>
    <td>
      <strong>
        Pre-feasibility
      </strong>
    </td>
    <td>{{form.preFeasability.desc}}</td>
  </tr>
  <tr>
    <td>Stage</td>
    <td>{{form.preFeasability.stage}}</td>
  </tr>
  <tr>
    <td>Est Cost</td>
    <td>{{form.preFeasability.estimatedCost}}</td>
  </tr>
  <tr>
    <td>Financers</td>
    <td>{{form.preFeasability.financers}}</td>
  </tr>
  <tr>
    <td>
      <strong>
        Conceptual Design
      </strong>
    </td>
    <td>{{form.conceptualDesign.desc}}</td>
  </tr>
  <tr>
    <td>Stage</td>
    <td>{{form.conceptualDesign.stage}}</td>
  </tr>
  <tr>
    <td>Est Cost</td>
    <td>{{form.conceptualDesign.estimatedCost}}</td>
  </tr>
  <tr>
    <td>Financers</td>
    <td>{{form.conceptualDesign.financers}}</td>
  </tr>
  <tr>
    <td>
      <strong>
        Feasibility study
      </strong>
    </td>
    <td>{{form.feasibilityStudy.desc}}</td>
  </tr>
  <tr>
    <td>Stage</td>
    <td>{{form.feasibilityStudy.stage}}</td>
  </tr>
  <tr>
    <td>Est Cost</td>
    <td>{{form.feasibilityStudy.estimatedCost}}</td>
  </tr>
  <tr>
    <td>Financers</td>
    <td>{{form.feasibilityStudy.financers}}</td>
  </tr>
  <tr>
    <td>
      <strong>
        Preliminary design
      </strong>
    </td>
    <td>{{form.preliminaryDesign.desc}}</td>
  </tr>
  <tr>
    <td>Stage</td>
    <td>{{form.preliminaryDesign.stage}}</td>
  </tr>
  <tr>
    <td>Est Cost</td>
    <td>{{form.preliminaryDesign.estimatedCost}}</td>
  </tr>
  <tr>
    <td>Financers</td>
    <td>{{form.preliminaryDesign.financers}}</td>
  </tr>
  <tr>
    <td>
      <strong>
        Esia
      </strong>
    </td>
    <td>{{form.esia.desc}}</td>
  </tr>
  <tr>
    <td>Stage</td>
    <td>{{form.esia.stage}}</td>
  </tr>
  <tr>
    <td>Est Cost</td>
    <td>{{form.esia.estimatedCost}}</td>
  </tr>
  <tr>
    <td>Financers</td>
    <td>{{form.esia.financers}}</td>
  </tr>
  <tr>
    <td>
      <strong>
        Permits & authorizations plan
      </strong>
    </td>
    <td>{{form.permitsAuthorization.desc}}</td>
  </tr>
  <tr>
    <td>Stage</td>
    <td>{{form.permitsAuthorization.stage}}</td>
  </tr>
  <tr>
    <td>Est Cost</td>
    <td>{{form.permitsAuthorization.estimatedCost}}</td>
  </tr>
  <tr>
    <td>Financers</td>
    <td>{{form.permitsAuthorization.financers}}</td>
  </tr>
  <tr>
    <td>
      <strong>
        Permits LEA
      </strong>
    </td>
    <td>{{form.permitsLea.desc}}</td>
  </tr>
  <tr>
    <td>Stage</td>
    <td>{{form.permitsLea.stage}}</td>
  </tr>
  <tr>
    <td>Est Cost</td>
    <td>{{form.permitsLea.estimatedCost}}</td>
  </tr>
  <tr>
    <td>Financers</td>
    <td>{{form.permitsLea.financers}}</td>
  </tr>
  <tr>
    <td>
      <strong>
        Main design detailed design
      </strong>
    </td>
    <td>{{form.mainDesignDetailedDesign.desc}}</td>
  </tr>
  <tr>
    <td>Stage</td>
    <td>{{form.mainDesignDetailedDesign.stage}}</td>
  </tr>
  <tr>
    <td>Est Cost</td>
    <td>{{form.mainDesignDetailedDesign.estimatedCost}}</td>
  </tr>
  <tr>
    <td>Financers</td>
    <td>{{form.mainDesignDetailedDesign.financers}}</td>
  </tr>
  <h2>Construction pre-Construction</h2>
  <tr>
    <td>
      <strong>
        Preparation of Tender
      </strong>
    </td>
    <td>{{form.preparationTender.desc}}</td>
  </tr>
  <tr>
    <td>Stage</td>
    <td>{{form.preparationTender.stage}}</td>
  </tr>
  <tr>
    <td>Cost-capex</td>
    <td>{{form.preparationTender.costCapex}}</td>
  </tr>
  <tr>
    <td>Financers</td>
    <td>{{form.preparationTender.financers}}</td>
  </tr>
  <tr>
    <td>
      <strong>
        Contracting Works
      </strong>
    </td>
    <td>{{form.contractingWorks.desc}}</td>
  </tr>
  <tr>
    <td>Stage</td>
    <td>{{form.contractingWorks.stage}}</td>
  </tr>
  <tr>
    <td>Cost-capex</td>
    <td>{{form.contractingWorks.costCapex}}</td>
  </tr>
  <tr>
    <td>Financers</td>
    <td>{{form.contractingWorks.financers}}</td>
  </tr>
  <tr>
    <td>
      <strong>
        Contracting of Supervision
      </strong>
    </td>
    <td>{{form.contractingOfSupervision.desc}}</td>
  </tr>
  <tr>
    <td>Stage</td>
    <td>{{form.contractingOfSupervision.stage}}</td>
  </tr>
  <tr>
    <td>Cost-capex</td>
    <td>{{form.contractingOfSupervision.costCapex}}</td>
  </tr>
  <tr>
    <td>Financers</td>
    <td>{{form.contractingOfSupervision.financers}}</td>
  </tr>

  <tr>
    <td>
      <strong>
        Construction : Works
      </strong>
    </td>
    <td>{{form.contractingOfSupervision.works.desc}}</td>
  </tr>
  <tr>
    <td>Stage</td>
    <td>{{form.contractingOfSupervision.works.stage}}</td>
  </tr>
  <tr>
    <td>Estimated Cost</td>
    <td>{{form.contractingOfSupervision.works.estimatedCost}}</td>
  </tr>
  <tr>
    <td>Financers</td>
    <td>{{form.contractingOfSupervision.works.financers}}</td>
  </tr>
  <tr>
    <td>
      <strong>
        Construction : Quality Assurance
      </strong>
    </td>
    <td>{{form.contractingOfSupervision.qualityAssurance.desc}}</td>
  </tr>
  <tr>
    <td>Stage</td>
    <td>{{form.contractingOfSupervision.qualityAssurance.stage}}</td>
  </tr>
  <tr>
    <td>Estimated Cost</td>
    <td>{{form.contractingOfSupervision.qualityAssurance.estimatedCost}}</td>
  </tr>
  <tr>
    <td>Financers</td>
    <td>{{form.contractingOfSupervision.qualityAssurance.financers}}</td>
  </tr>



  <td>
    <strong>
      Operations
    </strong>
  </td>

  <tr>
    <td>
      <strong>
        Commissioning/Handover
      </strong>
    </td>
    <td>{{form.commisioningHandover.desc}}</td>
  </tr>
  <tr>
    <td>Estimated Cost</td>
    <td>{{form.commisioningHandover.estCost}}</td>
  </tr>
  <tr>
    <td>Financers</td>
    <td>{{form.commisioningHandover.financers}}</td>
  </tr>
  <tr>
    <td>
      <strong>
        Operations
      </strong>
    </td>
    <td>{{form.operations.desc}}</td>
  </tr>
  <tr>
    <td>Estimated Cost</td>
    <td>{{form.operations.estCost}}</td>
  </tr>
  <tr>
    <td>Financers</td>
    <td>{{form.operations.financers}}</td>
  </tr>
  <tr>
    <td>
      <strong>
        Cashflow/control/accounting
      </strong>
    </td>
    <td>{{form.cashflowControlAccounting.desc}}</td>
  </tr>
  <tr>
    <td>Estimated Cost</td>
    <td>{{form.cashflowControlAccounting.estCost}}</td>
  </tr>
  <tr>
    <td>Financers</td>
    <td>{{form.cashflowControlAccounting.financers}}</td>
  </tr>
  <tr>
    <td>
      <strong>
        HR / TAX / LEGAL
      </strong>
    </td>
    <td>{{form.hrTaxLegal.desc}}</td>
  </tr>
  <tr>
    <td>Estimated Cost</td>
    <td>{{form.hrTaxLegal.estCost}}</td>
  </tr>
  <tr>
    <td>Financers</td>
    <td>{{form.hrTaxLegal.financers}}</td>
  </tr>
  <tr>
    <td>
      <strong>
        Control / Supervision / Monitoring
      </strong>
    </td>
    <td>{{form.controlSuperVisionMonitoring.desc}}</td>
  </tr>
  <tr>
    <td>Estimated Cost</td>
    <td>{{form.controlSuperVisionMonitoring.estCost}}</td>
  </tr>
  <tr>
    <td>Financers</td>
    <td>{{form.controlSuperVisionMonitoring.financers}}</td>
  </tr>
</table> -->
<!-- </ng-container> -->