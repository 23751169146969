import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MapComponent } from './map/map.component';
import { ClarityModule } from '@clr/angular';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { FormsModule } from '@angular/forms';
import { ProjectFormComponent } from './project-form/project-form.component';
import { LeafletDrawModule } from '@asymmetrik/ngx-leaflet-draw';
import { HomeComponent } from './home/home.component';
import { AboutComponent } from './about/about.component';
import { BusinessConnectivityComponent } from './business-connectivity/business-connectivity.component';


@NgModule({
  declarations: [
    AppComponent,
    MapComponent,
    ProjectFormComponent,
    HomeComponent,
    AboutComponent,
    BusinessConnectivityComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ClarityModule,
    LeafletModule,
    LeafletDrawModule,
    LeafletModule,
    BrowserAnimationsModule,
    FormsModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
