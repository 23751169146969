<div class="main-container" style="background-color: white;">


  <div class="content-container">
    <div class="content-area" style="padding: 35px">
      <div class="my-header" style="width: 100%;">
        <div style="  display:inline-flex;">
          <div class="sagov">
            <img class='data' src="../assets/img/Connectivity Platform Logo.png">
          </div>
          <div class="data clr-row">
            <p style="font-size: 24px; font-weight: 1000; color: rgb(11, 78, 174) ;">
              Strengthening <br>
              South - Adriatic links </p>
          </div>
        </div>

        <div class="clr-grid">
          <div class="interreg-mobile">
            <div class="clr-row">
              <div class="clr-col-6"> <img src="../assets/img/logo_cdi_medium.png">
              </div>
              <div class="clr-col-6"> <img src="../assets/img/sagov.png">
              </div>
            </div>
          </div>

        </div>


        <div class="interreg-desktop" style="align-self:flex-end; margin-left: auto;">
          <img class='data' src="../assets/img/logo_cdi_medium.png">
          <img class='data' src="../assets/img/sagov.png">
        </div>

      </div>



      <header class="header-6" style="  background-color: rgb(23, 74, 143) !important;">
        <div class="header-nav">
          <a [routerLink]="['/home']" routerLinkActive="active" class="nav-link"><span class="nav-text"
              style="font-size: larger;">Home</span></a>
        </div>
        <div class="header-nav">
          <a [routerLink]="['/about']" routerLinkActive="active" class="nav-link"><span class="nav-text"
              style="font-size: larger;">About</span></a>
        </div>
        <div class="header-nav">
          <clr-dropdown>
            <a class="nav-link" >
              <div class="clr-row" clrDropdownTrigger>
                <span class="nav-text" style="font-size: larger; color: white;" >
                  Map
                </span>
                <clr-icon shape="caret" direction="up" style="position: revert;transform: rotate( 180deg); color: white;">
                </clr-icon>
              </div>
            </a>

            <clr-dropdown-menu clrPosition="bottom-left" *clrIfOpen>
              <div clrDropdownItem [routerLink]="['/map']"   style="outline: 0">Transport Connectivity </div>
              <div clrDropdownItem [routerLink]="['/business']"  style="outline: 0">Business Connectivity </div>
            </clr-dropdown-menu>
          </clr-dropdown>

        </div>
      </header>
      <router-outlet></router-outlet>
    </div>
  </div>
  <!-- <div class="footer" style="margin: 10px; ">
    <span style="font-size: 12px;">
      Disclaimer:
    
    Infractructure projects in the map are stylicised and may not reflect their exact trace or GIS location.
    Progress stage is updated regularly although some delays due to timing of their official publication may occur.
      </span>
  </div> -->
</div>