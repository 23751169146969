import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import * as L from 'leaflet';
import { tileLayer, latLng, polygon, polyline } from 'leaflet';
import data from '../../assets/data.json';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable'
import { fontMono } from 'src/assets/noto-mono';
import { environment } from 'src/environments/environment';
import businessDataJson from '../../assets/business-data.json'
import { keyframes } from '@angular/animations';


@Component({
  selector: 'app-business-connectivity',
  templateUrl: './business-connectivity.component.html',
  styleUrls: ['./business-connectivity.component.scss']
})
export class BusinessConnectivityComponent implements OnInit {

  @ViewChild('simple_table') simple_table: ElementRef;
  businessData = businessDataJson;
  template
  subSectorSelection = {
    textile: false,
    food: false,
    other: false
  }
  basic = false;
  lastSelectionCategory = '';
  lastSelectionIndex = -1;
  filterId;
  mobile = false;
  pdfMake;
  _d;
  _toggled = false;
  form = {
    name: '',
    industry: '',
    products: '',
    location: '',
    employees: '',
    email: '',
    year: '',
    tel: '',
    website: '',
  }
  polylines: L.LayerGroup = L.layerGroup();
  map;


  options = {
    layers: [
      tileLayer('https://gisco-services.ec.europa.eu/maps/tiles/OSMPositronCompositeEN/EPSG3857/{z}/{x}/{y}.png', { maxZoom: 18, attribution: 'Disclaimer: Infrastructure projects in the map are styled accordingly, and may not reflect their exact trace or GIS location. Progress stage is updated regularly, although some delays due to timing of their official publication may occur.' })
      // tileLayer('https://europa.eu/webtools/maps/tiles/osm-ec//{z}/{x}/{y}.png', { maxZoom: 18, attribution: 'Disclaimer: Infrastructure projects in the map are styled accordingly, and may not reflect their exact trace or GIS location. Progress stage is updated regularly, although some delays due to timing of their official publication may occur.' })
    ],
    zoom: 8,
    center: latLng(41.047804, 19.005773)
  };
  polygon2 = [
    { "lat": 41.376351404412, "lng": 19.45879737264477 },
    { "lat": 41.385333263035804, "lng": 19.481503277784217 },
    { "lat": 41.34875207406634, "lng": 19.515230421675373 },
    { "lat": 41.33926005612147, "lng": 19.48603088851087 },
    { "lat": 41.37536208279361, "lng": 19.458187735872347 }]
    ;
  polygonPoints = [
    { "lat": 42.18616168152669, "lng": 19.420695954468105 },
    { "lat": 42.186478062171105, "lng": 19.420550434151664 },
    { "lat": 42.183735482410846, "lng": 19.426707057282332 },
    { "lat": 42.201471887481176, "lng": 19.44738632300869 },
    { "lat": 42.201660049480495, "lng": 19.451221892377365 },
    { "lat": 42.20531211920117, "lng": 19.455862103495747 },
    { "lat": 42.20925422319156, "lng": 19.463920894777402 },
    { "lat": 42.21316076897802, "lng": 19.467588532716036 },
    { "lat": 42.218315381574506, "lng": 19.46826224913821 },
    { "lat": 42.222677433078445, "lng": 19.45813915226609 },
    { "lat": 42.22534330528628, "lng": 19.44482221500948 },
    { "lat": 42.217996072037565, "lng": 19.43478373344988 },
    { "lat": 42.211733434734754, "lng": 19.42399855004624 },
    { "lat": 42.209583863488945, "lng": 19.42299112910405 },
    { "lat": 42.199790842423475, "lng": 19.43588088266552 },
    { "lat": 42.19809173333683, "lng": 19.434246851596985 },
    { "lat": 42.18744145954642, "lng": 19.421616779873148 }
  ];

  constructor(private cd: ChangeDetectorRef) { }

  ngOnInit(): void {
    if (window.screen.width < 780) { // 768px portrait
      this.mobile = true;
    }
  }

  onMapReady(map) {
    this.map = map;
    L.polygon(this.polygonPoints, { color: 'gray', dashArray: "2 8" }).addTo(map)
    L.marker(L.latLng(this.polygonPoints[10].lat, this.polygonPoints[10].lng)
      ,
      {
        icon: L.divIcon({
          html: `<div style="height: 50px; width: 50px; font-size: 24px;">
                   <i class="fas fa-flag"></i>
              </div>`
          ,
          className: 'noclass',
          iconAnchor: [13, 9],
          iconSize: [24, 24]
        }),
      },
    ).bindPopup(
      `The Map also contains information about the   <i class="fas fa-flag"></i> Free Economic Zones established in the Area. Click  <a style="cursor: pointer;" href="../../assets/Pershkrimi - Free Economic  Zones.pdf" target="_blank"><u>here</u></a> for more information.`
    ).addTo(map);
    L.polygon(this.polygon2, { color: 'gray', dashArray: "2 8" }).addTo(map)
    const marker2 = L.marker(L.latLng(this.polygon2[4].lat, this.polygon2[4].lng)
      ,
      {
        icon: L.divIcon({
          html: `<div style="height: 50px; width: 50px; font-size: 24px;">
                 <i class="fas fa-flag"></i>
            </div>`
          ,
          className: 'noclass',
          iconAnchor: [13, 9],
          iconSize: [24, 24]
        }),
      },
    )
    marker2.bindPopup(
      `The Map also contains information about the   <i class="fas fa-flag"></i> Free Economic Zones established in the Area. Click <a style="cursor: pointer;" href="../../assets/Pershkrimi - Free Economic  Zones.pdf" target="_blank"><u>here</u></a> for more information.`
    ).addTo(map);
    this.filter();
  }

  onFilter() {
    clearTimeout(this.filterId);
    this.filterId = setTimeout(() => {
      this.filter();
    }, 100);
  }

  private drawShape(data, customColor?, weight?, dashArray?) {
    if (!data.coordinates) {
      return;
    }
    if (data.coordinates.length === 1) {
      let html = `<i class="fas fa-map-marker-alt" style="color: rgb(24, 62, 184)  !important;"></i>`;
      if (data.industry === 'Food and Agriculture') {
        html = `<i class="fas fa-map-marker-alt" style="color: rgba(168, 205, 111) !important;"></i>`;
      }
      if (data.industry === 'Textile and Leather') {
        html = `<i class="fas fa-map-marker-alt " style="color: rgba(163, 113, 80) ;" ></i>`;
      }

      return L.marker(L.latLng(data.coordinates[0].lng, data.coordinates[0].lat)
        ,
        {
          icon: L.divIcon({
            html: `<div style="height: 50px; width: 50px; font-size: 24px;">
                      ${html}
                  </div>`
            ,
            className: 'noclass',
            iconAnchor: [13, 9],
            iconSize: [24, 24]
          }),
        },
      );
    }


  }

  filter() {

    if (this.polylines) {
      this.map.removeLayer(this.polylines);
    }
    let polylines: L.LayerGroup = L.layerGroup();

    let feature = [];

    let filteredData = this.businessData.filter(el => {
      return (this.subSectorSelection.textile && (el.industry == 'Textile and Leather')) ||
        (this.subSectorSelection.food && (el.industry == 'Food and Agriculture')) ||
        (this.subSectorSelection.other && (el.industry == 'Other'));
    })
    console.log(filteredData)

    filteredData.forEach((data: any) => {
      if (data.coordinates) {
        polylines.addLayer(this.drawShape(data).bindPopup(`
        <table class='custom' style="min-width: 400px;">        
        <tr style="background-color: lightgray">
          <td>Enterprise Name</td>
          <td style="min-width: 50px; float: left; text-align-last: left; text-align: left;">${data.name ? data.name : ' '}</td>
        </tr>
        <tr>
          <td>Year Established</td>
          <td style="min-width: 50px; float: left; text-align-last: left; text-align: left;">${data.year ? data.year : ' '}</td>
        </tr>
        <tr style="background-color: lightgray">
          <td>Industry</td>
          <td style="min-width: 50px; float: left; text-align-last: left; text-align: left;">${data.industry ? data.industry : ' '}</td>
        </tr>
        <tr >
          <td>Products Category</td>
          <td style="min-width: 50px; float: left; text-align-last: left; text-align: left;">${data.products ? data.products : ' '}</td>
        </tr>
        <tr style="background-color: lightgray">
          <td>nr of Employees</td>
          <td style="min-width: 50px; float: left; text-align-last: left; text-align: left;">${data.employees ? data.employees : ' '}</td>
        </tr>
        <tr>
          <td>Address</td>
          <td style="min-width: 50px; float: left; text-align-last: left; text-align: left;">${data?.location} </td>
        </tr>
        <tr style="background-color: lightgray">
          <td>Email & Tel</td>
          <td style="min-width: 50px; float: left; text-align-last: left; text-align: left;">${data?.email} ${data?.tel} </td>
        </tr>
        <tr >
          <td>Website</td>
          <td style="min-width: 50px; float: left; text-align-last: left; text-align: left;">${data?.website}</td>
        </tr>
        <tr>
        <td></td>
        <td>      <a  id="button-submit" style="cursor:pointer" right: 10px;'> more</a> </td>
        </tr>
      </table>
        `, {
          minWidth: 400
        }).on("preclick", () => {
          this.form = data;
        })
          .on("click", () => {
            let buttonSubmit = L.DomUtil.get('button-submit');
            L.DomEvent.addListener(buttonSubmit, 'click', async () => {
              this.printPDF(data);
            });
          }));
        feature.push(this.drawShape(data));
      }

    });
    this.polylines = polylines;


    this.polylines.addTo(this.map)
    let featureGroup: L.FeatureGroup = L.featureGroup(feature);

    if (feature) {
      if (feature.length > 0) {
        // this.map.fitBounds(featureGroup.getBounds());
      }
    }
  }



  async printPDF(data) {
    this.form = data;
    let table = document.getElementById('simple_table')
    table.style.display = 'block'
    this.cd.detectChanges()
    this.cd.markForCheck();
    this._d = new Date()
    let doc = new jsPDF();
    doc.addFileToVFS('Amiri-Regular.ttf', fontMono);
    doc.addFont('Amiri-Regular.ttf', 'Amiri', 'normal');
    doc.setFont('Amiri'); // set font
    doc.setFontSize(20);
    autoTable(doc, {
      html: '#simple_table',
      styles: {
        font: 'Amiri',
        fontStyle: 'normal',
      },
      didDrawPage: function (data) {
        // Header
        doc.setFontSize(20);
        doc.setTextColor(40);
        const sagovJPG = `/assets/img/sagov.jpg`;
        if (sagovJPG) {
          doc.addImage(sagovJPG, 'JPEG', data.settings.margin.left, 2, 80, 26);
        }
        // Footer
        // @ts-ignore
        let str = "Page " + doc.internal.getNumberOfPages();

        let today = new Date();
        let dd = String(today.getDate()).padStart(2, '0');
        let mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        let yyyy = today.getFullYear();
        let generated = 'Generated at ' + mm + '/' + dd + '/' + yyyy

        let disclaimer = 'Disclaimer: Infrastructure projects in the map are styled and may not reflect their exact trace or GIS location.'
        let disclaimer1 = 'Progress stage is updated regularly although, some delays due to timing of their official publication, may occur.'
        // Total page number plugin only available in jspdf v1.0+
        if (typeof doc.putTotalPages === 'function') {
          str = str;
        }
        doc.setFontSize(7);

        let pageSize = doc.internal.pageSize;
        let pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
        doc.text(str, data.settings.margin.left, pageHeight - 13);
        doc.text(generated, 160, pageHeight - 13);
        doc.text(disclaimer, data.settings.margin.left, pageHeight - 8);
        doc.text(disclaimer1, data.settings.margin.left, pageHeight - 3);
        // doc.text(str, data.settings.margin.left, pageHeight - 10);
      },
      margin: { bottom: 28, top: 30 }
    })

    doc.save(`Sagov-${this.form.name}.pdf`);
    setTimeout(() => {
      table.style.display = 'none'
    }, 1000);
  }

  toggle() {
    this._toggled = !this._toggled;
    setTimeout(() => {
      this.map.invalidateSize();
    }, 1000);
  }


}
